import { defineStore } from 'pinia'

interface State {
  default_address: number | null
}

export const useUserPreferencesStore = defineStore('UserPreferences', {
  state: (): State => ({
    default_address: null,
  }),
  getters: {
    jsonPreferences(): string {
      return JSON.stringify({
        default_address: this.default_address,
      })
    },
  },
  actions: {
    init() {
      const json = localStorage.getItem('user_preferences') ?? '{}'
      try {
        const preferences = JSON.parse(json)
        this.default_address = preferences.default_address
      } catch (error) {
        localStorage.removeItem('user_preferences')
      }
    },
    setDefaultAddress(addressId: number) {
      this.default_address = addressId
      this.storePreferenceToLocalStorage()
    },
    storePreferenceToLocalStorage() {
      localStorage.setItem('user_preferences', this.jsonPreferences)
    },
  },
})

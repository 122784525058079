<script setup lang="ts">
  import { usePartnerWhatsapp } from '@/model/partner'
  import { usePartnerStore } from '@/stores/partner'

  const partner = usePartnerStore()

  const { whatsappLink } = usePartnerWhatsapp()
</script>

<template>
  <a
    v-if="partner.partner?.whatsapp"
    class="inline-flex p-1"
    target="_blank"
    :href="whatsappLink()"
  >
    <fa-icon icon="i-fas-message-lines" fixed-width></fa-icon>
  </a>
</template>

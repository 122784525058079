<script setup lang="ts">
  import AppToast from '@/components/AppToast.vue'
  import PwaUpdatePrompt from '@/components/ThePWAUpdatePrompt.vue'
  import { useApiStore } from '@/stores/api'
  import { useUserPreferencesStore } from '@/stores/userPreferences'

  const api = useApiStore()

  api.GET('/sanctum/csrf-cookie')

  const userPreferences = useUserPreferencesStore()
  userPreferences.init()
</script>

<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
  <pwa-update-prompt></pwa-update-prompt>
  <app-toast></app-toast>
</template>

import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

export const usePageStore = defineStore('Page', () => {
  const showError404 = ref(false)
  const shopInitialized = ref(false)

  const showNotFoundError = () => {
    showError404.value = true
  }
  const resetErrorState = () => {
    showError404.value = false
  }
  const setShopInitialized = () => {
    shopInitialized.value = true
  }

  const loginRoute = (): RouteLocationRaw => {
    const path = location.pathname.substring(1) + location.search
    const query = path ? { r: path } : {}
    return { name: 'login', query }
  }

  return {
    showError404,
    shopInitialized,
    showNotFoundError,
    resetErrorState,
    setShopInitialized,
    loginRoute,
  }
})

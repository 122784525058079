import { computed, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { debounce } from '@/functions'
import copy from '@/functions/copy'
import {
  CANCEL_REQUESTED,
  CANCELED,
  COMPLAINED,
  COMPLAINT_RESPONDED,
  COMPLETED,
  DELIVERING,
  FAILED,
  NEW,
  PENDING,
  PROCESSING,
  RECEIVED,
  SUCCESS,
  WAITING_PAYMENT,
} from '@/functions/enums/orderStatus'
import {
  BERHASIL,
  BERLANGSUNG,
  DIKIRIM,
  DIKOMPLAIN,
  DIPROSES,
  DITERIMA,
  GAGAL,
  KOMPLAIN_DIJAWAB,
  MENUNGGU,
  MINTA_BATAL,
  SELESAI,
} from '@/functions/enums/orderStatus'
import { useApiStore } from '@/stores/api'
import { usePartnerStore } from '@/stores/partner'
import { useToastStore } from '@/stores/toast'
import { CATEGORY_ID } from './digitalProduct'

const ORDER_TYPES_SHOPPING = 'belanja'
const ORDER_TYPES_PULSA_OPERATOR = 'pulsa_operator'
const ORDER_TYPES_PLN_TOKEN = 'token_pln'

export function usePartnerApp() {
  const route = useRoute()
  const router = useRouter()

  const redirectToPartnerRoutes = () => {
    console.log('[redirectToPartnerRoutes]')
    const routeName = route.name as string

    if (routeName.includes('partner')) return

    switch (route.name) {
      case 'cart':
        router.replace({ name: 'partner cart' })
        break
      case 'product':
        router.replace({
          name: 'partner product',
          params: { id: route.params.id },
        })
        break
      case 'category':
        router.replace({ name: 'partner category' })
        break
      case 'category detail':
        router.replace({
          name: 'partner category detail',
          params: { id: route.params.id },
        })
        break
      case 'orders':
        router.replace({ name: 'partner order list' })
        break
      case 'order detail':
        router.replace({
          name: 'partner order detail',
          params: { id: route.params.id },
        })
        break
      case 'checkout':
        router.replace({ name: 'partner checkout' })
        break
      case 'notification':
        router.replace({ name: 'partner notification' })
        break
      case 'product digital payment':
        router.replace({ name: 'partner product digital payment' })
        break
      // general page, don't redirect
      case 'product digital':
      case 'product digital pulsa':
      case 'product digital internet':
      case 'product digital listrik':
      case 'product digital checkout':
      case 'product digital favorite number':
      case 'changelog':
      case 'maintenance':
        break
      // other pages that don't exist on partner app
      default:
        router.replace({ name: 'partner home' })
    }
  }

  const redirectToRegularRoutes = () => {
    console.log('[redirectToRegularRoutes]')
    switch (route.name) {
      case 'partner cart':
        router.replace({ name: 'cart' })
        break
      case 'partner product':
        router.replace({ name: 'product', params: { id: route.params.id } })
        break
      case 'partner order list':
        router.replace({ name: 'orders' })
        break
      case 'partner order detail':
        router.replace({
          name: 'order detail',
          params: { id: route.params.id },
        })
        break
      case 'partner checkout':
        router.replace({ name: 'checkout' })
        break
      case 'partner notification':
        router.replace({ name: 'notification' })
        break
      case 'partner category':
        router.replace({ name: 'category' })
        break
      case 'partner category detail':
        router.replace({ name: 'category detail', params: { id: route.params.id } })
        break
      case 'partner product digital payment':
        router.replace({ name: 'product digital payment' })
        break
    }
  }

  return {
    redirectToPartnerRoutes,
    redirectToRegularRoutes,
  }
}

export function usePartnerProductList() {
  const api = useApiStore()

  const loading = ref(false)
  const partnerProducts = ref<PartnerProduct[]>([])
  const pagination = ref<Pagination>()

  type Params = {
    page?: number
    sort?: string
    category?: number
  }

  const getPartnerProducts = async (params?: Params) => {
    try {
      loading.value = true
      const response = await api.GET<ApiResponse<PartnerProduct>>('/partner/product', params)
      partnerProducts.value = partnerProducts.value.concat(response.data)
      pagination.value = response.meta
    } finally {
      loading.value = false
    }
  }

  return {
    getPartnerProducts,
    partnerProducts,
    pagination,
    loading,
  }
}

export function usePartnerProductDetail() {
  const api = useApiStore()

  const loading = ref(false)
  const partnerProduct = ref<PartnerProductDetail>()

  const getPartnerProduct = async (id: number) => {
    type PartnerProductDetailResponse = {
      partner_product: PartnerProductDetail
      similar_products: PartnerProduct[]
      last_visited: PartnerProductDetail[]
    }
    try {
      loading.value = true
      const response = await api.GET<PartnerProductDetailResponse>('partner/product/' + id)
      partnerProduct.value = response.partner_product
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    partnerProduct,
    getPartnerProduct,
  }
}

export function usePartnerCart() {
  const partner = usePartnerStore()
  const api = useApiStore()

  const loading = ref(false)
  const getPartnerCarts = async () => {
    try {
      loading.value = true
      const response = await api.GET<ApiResponse<PartnerCustomerCart>>('partner/shopping-cart')
      partner.initCart(response.data)
    } finally {
      loading.value = false
    }
  }

  const postUpdate = async (partnerProductId: number, qty: number) => {
    await api.POST('partner/shopping-cart', {
      partner_product_id: partnerProductId,
      qty,
    })
  }

  const deb = debounce(postUpdate, 500)

  const updateCart = (partnerProductId: number, qty: number) => {
    partner.updateCart(partnerProductId, qty)
    deb(partnerProductId, qty)
  }

  const deleteFromCart = async (partnerProductId: number) => {
    await postUpdate(partnerProductId, 0)
    partner.removeItemFromCart(partnerProductId)
  }

  const clearCart = async () => {
    await api.DELETE('partner/shopping-cart/all')
    partner.clearCart()
  }

  return {
    getPartnerCarts,
    updateCart,
    deleteFromCart,
    clearCart,
  }
}

export function usePartnerCartSelect() {
  const partner = usePartnerStore()
  const api = useApiStore()

  const selectOne = (partnerProductId: number) => {
    partner.toggleSelectCart(true, [partnerProductId])
    api.POST('partner/shopping-cart/select', {
      partner_product_id: [partnerProductId],
    })
  }
  const unselectOne = (partnerProductId: number) => {
    partner.toggleSelectCart(false, [partnerProductId])
    api.POST('partner/shopping-cart/unselect', {
      partner_product_id: [partnerProductId],
    })
  }
  const selectAll = () => {
    const ids = partner.carts.map((item) => item.partner_product_id)
    partner.toggleSelectCart(true, ids)
    api.POST('partner/shopping-cart/select', {
      partner_product_id: ids,
    })
  }
  const unselectAll = () => {
    const ids = partner.carts.map((item) => item.partner_product_id)
    partner.toggleSelectCart(false, ids)
    api.POST('partner/shopping-cart/unselect', {
      partner_product_id: ids,
    })
  }

  return {
    selectOne,
    unselectOne,
    selectAll,
    unselectAll,
  }
}

export function usePartnerPaymentMethod() {
  const api = useApiStore()

  const loading = ref(false)
  const paymentMethods = ref<PartnerPaymentMethod[]>([])

  const getPaymentMethods = async () => {
    try {
      loading.value = true
      const response = await api.GET<ApiResponse<PartnerPaymentMethod>>('partner/payment-method')
      paymentMethods.value = response.data
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    paymentMethods,
    getPaymentMethods,
  }
}

export function usePartnerCheckout() {
  const route = useRoute()

  const partner = usePartnerStore()
  const api = useApiStore()

  const { deleteFromCart } = usePartnerCart()

  type CheckoutData = {
    address: string
    payment_method_id: number
    products: { id: number; qty: number }[]
  }

  const errors = ref<FormError>({})
  const submitting = ref(false)
  const submitted = ref(false)
  const totalSubmit = ref(0)
  const checkout = async (data: CheckoutData, total: number) => {
    try {
      submitting.value = true
      await api.POST('partner/shop-order', data)
      submitted.value = true
      totalSubmit.value = total
      removeProductFromCart()
    } catch (error) {
      errors.value = api.formErrors(error)
    } finally {
      submitting.value = false
    }
  }

  const removeProductFromCart = async () => {
    // direct buy don't need to remove product from cart
    if (route.query.produk) {
      return
    }

    // remove product from cart
    partner.carts
      .filter((item) => item.selected)
      .forEach((item) => deleteFromCart(item.partner_product_id))
  }

  return {
    submitting,
    checkout,
    errors,
    submitted,
    totalSubmit,
  }
}

export function usePartnerCustomerAddress() {
  const partner = usePartnerStore()
  const api = useApiStore()

  const customerAddress = ref<PartnerCustomerAddress>()
  const form = reactive({
    label: '',
    address: '',
  })
  const submitting = ref(false)
  const errors = ref<FormError>({})

  const storeAddress = async () => {
    try {
      submitting.value = true
      const response = await api.POST<PartnerCustomerAddress>('user-address', form)
      partner.insertAddress(response)
    } catch (error) {
      errors.value = api.formErrors(error)
      throw error
    } finally {
      submitting.value = false
    }
  }
  const updateAddress = async () => {
    if (!customerAddress.value) return

    try {
      submitting.value = true
      const response = await api.PUT<PartnerCustomerAddress>(
        'user-address/' + customerAddress.value.id,
        form,
      )
      partner.updateAddress(response)
    } catch (error) {
      errors.value = api.formErrors(error)
      throw error
    } finally {
      submitting.value = false
    }
  }
  const deleteAddress = async () => {
    if (!customerAddress.value) return

    try {
      submitting.value = true
      await api.DELETE('user-address/' + customerAddress.value.id)
      partner.removeAddress(customerAddress.value.id)
    } finally {
      submitting.value = false
    }
  }

  return {
    form,
    errors,
    submitting,
    customerAddress,
    storeAddress,
    updateAddress,
    deleteAddress,
  }
}

export function usePartnerWhatsapp() {
  const partner = usePartnerStore()
  const whatsappLink = (text = '') => {
    const baseUrl = 'https://api.whatsapp.com/send'

    const phone = partner.partner?.whatsapp ?? ''

    const query: Record<string, string> = {
      phone,
    }
    if (text) {
      query.text = text
    }

    const queryString = new URLSearchParams(query).toString()

    return baseUrl + '?' + queryString
  }

  return {
    whatsappLink,
  }
}

export function usePartnerShopOrderList() {
  const route = useRoute()
  const routeName = route.name as string

  const api = useApiStore()

  const loading = ref(true)
  const pagination = ref<Pagination>()
  const orders = ref<(PartnerOrder | PartnerOrderDigital)[]>([])

  const apiParamsStatus = computed(() => {
    let result: string[] = []
    switch (route.query.status as string) {
      case BERLANGSUNG:
        result = [
          WAITING_PAYMENT,
          PROCESSING,
          DELIVERING,
          RECEIVED,
          CANCEL_REQUESTED,
          COMPLAINED,
          COMPLAINT_RESPONDED,
          PENDING,
        ]
        break
      case MENUNGGU:
        result = [NEW]
        break
      case DIPROSES:
        result = [PROCESSING]
        break
      case DIKIRIM:
        result = [DELIVERING]
        break
      case DITERIMA:
        result = [RECEIVED]
        break
      case MINTA_BATAL:
        result = [CANCEL_REQUESTED]
        break
      case DIKOMPLAIN:
        result = [COMPLAINED]
        break
      case KOMPLAIN_DIJAWAB:
        result = [COMPLAINT_RESPONDED]
        break
      case SELESAI:
        result = [CANCELED, COMPLETED, SUCCESS, FAILED]
        break
      case BERHASIL:
        result = [SUCCESS, COMPLETED]
        break
      case GAGAL:
        result = [CANCELED, FAILED]
        break
      default:
        result = []
    }

    if (result.length === 0) return undefined
    return result.join(',')
  })
  const apiParamsType = computed(() => {
    switch (route.query.tipe as string) {
      case ORDER_TYPES_SHOPPING:
        return { type: 'shopping' }
      case ORDER_TYPES_PULSA_OPERATOR:
        return {
          type: 'digital',
          parent_category_id: CATEGORY_ID.PULSA_OPERATOR,
        }
      case ORDER_TYPES_PLN_TOKEN:
        return {
          type: 'digital',
          parent_category_id: CATEGORY_ID.PLN,
        }
      default:
        return {}
    }
  })

  const apiParams = computed(() => {
    return {
      page: route.query.page,
      status: apiParamsStatus.value,
      ...apiParamsType.value,
    }
  })

  const getOrders = async () => {
    try {
      loading.value = true
      const response = await api.GET<ApiResponse<PartnerOrder | PartnerOrderDigital>>(
        'partner/shop-order/with-digital-shop-order',
        apiParams.value,
      )
      orders.value = response.data
      pagination.value = response.meta
    } finally {
      loading.value = false
    }
  }

  watch(
    () => route.fullPath,
    () => {
      if (routeName !== route.name) return
      getOrders()
    },
  )

  return {
    orders,
    pagination,
    getOrders,
    loading,
  }
}

export function usePartnerShopOrderDetail() {
  const route = useRoute()

  const toast = useToastStore()
  const api = useApiStore()

  const loading = ref(false)
  const order = ref<PartnerOrderDetail>()

  type GetOrderResponse = {
    data: PartnerOrderDetail
  }
  const getOrder = async () => {
    try {
      loading.value = true
      const response = await api.GET<GetOrderResponse>('partner/shop-order/' + route.params.id)
      order.value = response.data
    } finally {
      loading.value = false
    }
  }

  const copyInvoiceNumber = async () => {
    if (!order.value) return

    copy(order.value?.invoice_number)
    toast.add('Nomor invoice berhasil disalin')
  }

  return {
    loading,
    order,
    getOrder,
    copyInvoiceNumber,
  }
}

export function usePartnerProductCategoryList() {
  const api = useApiStore()
  const categories = ref<ProductCategory[]>([])
  const getCategories = async () => {
    const response = await api.GET<ApiResponse<ProductCategory>>('partner/product-category')
    categories.value = response.data
  }

  return {
    categories,
    getCategories,
  }
}

<script setup lang="ts">
  import { computed } from 'vue'
  import { useUserStore } from '@/stores/user'

  const user = useUserStore()
  const countUnreadNotifications = computed(() => user.count_notifications)
</script>

<template>
  <router-link :to="{ name: 'notification' }" class="relative inline-flex p-1">
    <fa-icon icon="i-fas-bell" :class="{ 'faa-ring animated': countUnreadNotifications }"></fa-icon>
    <div
      v-if="countUnreadNotifications"
      class="absolute right-0.5 top-0.5 h-2 w-2 rounded-full bg-red-500"
    ></div>
  </router-link>
</template>

<style lang="postcss" scoped>
  /* BELL */

  @-webkit-keyframes ring {
    0% {
      -webkit-transform: rotate(-15deg);
      transform: rotate(-15deg);
    }

    2% {
      -webkit-transform: rotate(15deg);
      transform: rotate(15deg);
    }

    4% {
      -webkit-transform: rotate(-18deg);
      transform: rotate(-18deg);
    }

    6% {
      -webkit-transform: rotate(18deg);
      transform: rotate(18deg);
    }

    8% {
      -webkit-transform: rotate(-22deg);
      transform: rotate(-22deg);
    }

    10% {
      -webkit-transform: rotate(22deg);
      transform: rotate(22deg);
    }

    12% {
      -webkit-transform: rotate(-18deg);
      transform: rotate(-18deg);
    }

    14% {
      -webkit-transform: rotate(18deg);
      transform: rotate(18deg);
    }

    16% {
      -webkit-transform: rotate(-12deg);
      transform: rotate(-12deg);
    }

    18% {
      -webkit-transform: rotate(12deg);
      transform: rotate(12deg);
    }

    20% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }

  @keyframes ring {
    0% {
      -webkit-transform: rotate(-15deg);
      -ms-transform: rotate(-15deg);
      transform: rotate(-15deg);
    }

    2% {
      -webkit-transform: rotate(15deg);
      -ms-transform: rotate(15deg);
      transform: rotate(15deg);
    }

    4% {
      -webkit-transform: rotate(-18deg);
      -ms-transform: rotate(-18deg);
      transform: rotate(-18deg);
    }

    6% {
      -webkit-transform: rotate(18deg);
      -ms-transform: rotate(18deg);
      transform: rotate(18deg);
    }

    8% {
      -webkit-transform: rotate(-22deg);
      -ms-transform: rotate(-22deg);
      transform: rotate(-22deg);
    }

    10% {
      -webkit-transform: rotate(22deg);
      -ms-transform: rotate(22deg);
      transform: rotate(22deg);
    }

    12% {
      -webkit-transform: rotate(-18deg);
      -ms-transform: rotate(-18deg);
      transform: rotate(-18deg);
    }

    14% {
      -webkit-transform: rotate(18deg);
      -ms-transform: rotate(18deg);
      transform: rotate(18deg);
    }

    16% {
      -webkit-transform: rotate(-12deg);
      -ms-transform: rotate(-12deg);
      transform: rotate(-12deg);
    }

    18% {
      -webkit-transform: rotate(12deg);
      -ms-transform: rotate(12deg);
      transform: rotate(12deg);
    }

    20% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }

  .faa-ring.animated,
  .faa-ring.animated-hover:hover,
  .faa-parent.animated-hover:hover > .faa-ring {
    -webkit-animation: ring 2s ease infinite;
    animation: ring 2s ease infinite;
  }
</style>

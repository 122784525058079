import { date, dateRange, dateRelative, getCountdownValue, lessThanOneDay } from './date'
import { number, padNumber } from './number'

export const fmt = {
  number,
  padNumber,
  date,
  dateRelative,
  dateRange,
  getCountdownValue,
  lessThanOneDay,
}

export default fmt

import type { RouteRecordRaw } from 'vue-router'

const Navbar = () => import('@/components/TheNavbarTop.vue')

const routes: RouteRecordRaw[] = [
  {
    path: '',
    name: 'product digital',
    components: {
      default: () => import('@/views/ProductDigital/PageFirst.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'Top-up Pulsa',
      },
    },
  },
  {
    path: 'pulsa-operator',
    name: 'product digital pulsa',
    components: {
      default: () => import('@/views/ProductDigital/PageSelectValuePulsa.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'Pulsa Operator',
      },
    },
  },
  {
    path: 'paket-internet',
    name: 'product digital internet',
    components: {
      default: () => import('@/views/ProductDigital/PageSelectValueInternet.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'Paket Internet',
      },
    },
  },
  {
    path: 'pulsa-listrik',
    name: 'product digital listrik',
    components: {
      default: () => import('@/views/ProductDigital/PageSelectValuePln.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'Pulsa Listrik',
      },
    },
  },
  {
    path: 'checkout',
    name: 'product digital checkout',
    components: {
      default: () => import('@/views/ProductDigital/PageCheckoutSummary.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'Checkout',
      },
    },
  },
  {
    path: 'pembayaran',
    name: 'product digital payment',
    components: {
      default: () => import('@/views/ProductDigital/PageSelectPayment.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'Pembayaran',
      },
    },
  },
  {
    path: 'nomor-favorit/:type(pulsa-operator|paket-internet|pulsa-listrik)',
    name: 'product digital favorite number',
    components: {
      default: () => import('@/views/ProductDigital/PageFavoriteNumber.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'Nomor Favorit',
      },
    },
  },
]

export default routes

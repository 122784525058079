import { defineStore } from 'pinia'

interface State {
  selected: DigitalProduct | null
  phone: string
  id_customer: string
  paymentPoints: number
  paymentCredits: number
  paymentMscash: number
}

export const useCheckoutDigitalStore = defineStore('CheckoutDigital', {
  state: (): State => ({
    selected: null,
    phone: '',
    id_customer: '',
    paymentPoints: 0,
    paymentCredits: 0,
    paymentMscash: 0,
  }),
  getters: {
    bill(state): number {
      return state.selected?.price ?? 0
    },
    totalPayment(state): number {
      return state.paymentCredits + state.paymentMscash + state.paymentPoints
    },
    remainingBill(): number {
      return this.bill - this.totalPayment
    },
  },
  actions: {
    selectProduct(product: DigitalProduct) {
      this.selected = product
    },
    setPhone(phone: string) {
      this.phone = phone
    },
    setIdCustomer(id: string) {
      this.id_customer = id
    },
  },
})

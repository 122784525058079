import { useSystemSettingsStore } from '@/stores/systemSettings'

export default function (text = '', phone?: string) {
  const baseUrl = 'https://api.whatsapp.com/send'
  let whatsapp = ''

  const systemSettings = useSystemSettingsStore()
  // const phone = '6281332588889' // TODO setting from server
  whatsapp = systemSettings.phone_whatsapp

  if (phone) {
    whatsapp = phone
  }

  const query: Record<string, string> = {
    phone: whatsapp,
  }
  if (text) {
    query.text = text
  }

  const queryString = new URLSearchParams(query).toString()

  return baseUrl + '?' + queryString
}

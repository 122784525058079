<script setup lang="ts">
  import { computed } from 'vue'
  import { useRoute } from 'vue-router'
  import WhatsappLink from '@/functions/composables/whatsapp'
  import { useDetailOrderStore } from '@/stores/detailOrder'

  const detailOrder = useDetailOrderStore()

  const route = useRoute()

  const whatsappText = computed(() => {
    if (['product'].includes((route.name ?? '') as string)) {
      return location.href + '\n\n'
    }
    if (route.name === 'order detail') {
      return 'Nomor transaksi: ' + detailOrder.order?.invoice_number + '\n\n'
    }
    return ''
  })

  const whatsappLink = computed(() => WhatsappLink(whatsappText.value))
</script>

<template>
  <a class="inline-flex cursor-pointer p-1" target="_blank" :href="whatsappLink">
    <fa-icon icon="i-fas-message-lines"></fa-icon>
  </a>
</template>

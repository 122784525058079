import { defineStore } from 'pinia'
import { useShoppingCartBundleAddOrRemove } from '@/model/shoppingCartBundle'
import { useUserStore } from './user'

interface State {
  carts: ShoppingCartBundle[]
}

const STORAGE_CART_BUNDLE = 'cart_bundle_items'
const STORAGE_CART_BUNDLE_CHECKOUT = 'checkout_cart_bundles'

export const useCartBundleStore = defineStore('CartBundle', {
  state: (): State => ({
    carts: [],
  }),
  getters: {
    cartSelected(): ShoppingCartBundle[] {
      return this.carts.filter((cart) => cart.selected && cart.qty > 0)
    },
    total(): number {
      return this.cartSelected.reduce((carryCart, cart) => {
        const total =
          cart.qty *
          cart.product_bundle.products.reduce((carryProduct, product) => {
            return (
              carryProduct + (product.retail_price - product.pivot.value_cash) * product.pivot.qty
            )
          }, 0)

        return carryCart + total
      }, 0)
    },
    getCart() {
      return (productBundleId: number): ShoppingCartBundle | undefined =>
        this.carts.find((cart) => cart.product_bundle_id === productBundleId)
    },
  },
  actions: {
    selectAll() {
      this.carts.forEach((cart) => {
        cart.selected = true
      })
    },
    deselectAll() {
      this.carts.forEach((cart) => {
        cart.selected = false
      })
    },
    setQtyCart(productBundle: ProductBundle, qty: number) {
      const userStore = useUserStore()
      const { submitAdd, submitRemove } = useShoppingCartBundleAddOrRemove()
      const cart = this.getCart(productBundle.id)
      if (cart) {
        cart.qty = qty
      } else {
        this.carts.push({
          id: 0,
          user_id: 0,
          qty,
          product_bundle: productBundle,
          product_bundle_id: productBundle.id,
        })
      }
      this.removeZeroQtyFromCart()
      if (userStore.isLoggedIn) {
        if (qty === 0) {
          submitRemove(productBundle.id)
        } else {
          submitAdd(productBundle.id, { qty })
        }
      }
    },
    removeFromCart(productBundleId: number) {
      this.carts = this.carts.filter((c) => c.product_bundle_id !== productBundleId)
    },
    removeZeroQtyFromCart() {
      this.carts = this.carts.filter((c) => c.qty > 0)
    },
    getMaxPurchase(bundle: ProductBundle) {
      return (
        bundle.products
          .filter((product) => product.stock !== null)
          .map((product) => Math.floor(product.stock / product.pivot.qty))
          .sort()
          .pop() ?? null
      )
    },
    checkLocalStorage() {
      const { submitAdd } = useShoppingCartBundleAddOrRemove()

      const storageItems = JSON.parse(
        localStorage.getItem(STORAGE_CART_BUNDLE) ?? '[]',
      ) as ShoppingCartBundle[]

      const userStore = useUserStore()

      storageItems.forEach((cart) => {
        const findItem = this.getCart(cart.product_bundle_id)
        if (!findItem) {
          this.carts.push(cart)
        } else {
          this.carts.forEach((data) => {
            data.selected = cart.selected
          })
        }

        if (userStore.isLoggedIn && (!findItem || findItem.qty !== cart.qty)) {
          submitAdd(cart.product_bundle_id, { qty: cart.qty })
        }
      })
    },
    setCarts(value: ShoppingCartBundle[]) {
      const cartsOld = [...this.carts]
      this.carts = value.map((data) => {
        const cartOld = cartsOld.find((old) => old.id === data.id)
        if (cartOld) {
          data.selected = cartOld.selected
        }

        return data
      })

      this.checkLocalStorage()
      this.updateLocalStorageCarts()
    },
    getCheckoutCarts() {
      try {
        return JSON.parse(
          localStorage.getItem(STORAGE_CART_BUNDLE_CHECKOUT) ?? '[]',
        ) as ShoppingCartBundle[]
      } catch (error) {
        return []
      }
    },
    updateLocalStorageCheckoutCarts() {
      // saves item id and qty to local storage, will be used in checkout page
      const json = JSON.stringify(this.cartSelected)
      localStorage.setItem(STORAGE_CART_BUNDLE_CHECKOUT, json)
    },
    markCheckoutItemsAsSelected() {
      const checkoutItems = this.getCheckoutCarts()
      checkoutItems.forEach((cart) => {
        const findItem = this.getCart(cart.product_bundle_id)
        if (findItem) {
          findItem.selected = true
        }
      })
      this.updateLocalStorageCheckoutCarts()
    },
    updateLocalStorageCarts() {
      // if user not logged in, save again to local storage, otherwise delete from localstorage
      const user = useUserStore()
      if (!user.isLoggedIn) {
        localStorage.setItem(STORAGE_CART_BUNDLE, JSON.stringify(this.carts))
      } else {
        localStorage.removeItem(STORAGE_CART_BUNDLE)
      }
    },
  },
})

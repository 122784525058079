import Navbar from '../components/TheNavbarTop.vue'

export default [
  {
    path: '',
    name: 'user',
    components: {
      default: () => import('../views/User/PageIndex.vue'),
      navbarTop: () => import('../views/User/NavbarTop.vue'),
    },
    props: {
      navbarTop: {
        bgClass: 'bg-primary-500 text-white',
      },
    },
  },
  {
    path: 'credit',
    name: 'credit',
    components: {
      default: () => import('../views/User/HistoryCredits.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'Riwayat Kredit',
      },
    },
  },
  {
    path: 'point',
    name: 'point',
    components: {
      default: () => import('../views/User/HistoryPoints.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'Riwayat Poin',
      },
    },
  },
  {
    path: 'change-password',
    name: 'change password',
    components: {
      default: () => import('../views/User/ChangePassword.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'Password',
      },
    },
  },
  {
    path: 'change-address',
    name: 'change default address',
    components: {
      default: () => import('../views/User/ChangeDefaultAddress.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'Alamat',
      },
    },
  },
  {
    path: 'credit-cards',
    name: 'manage credit cards',
    components: {
      default: () => import('../views/User/CreditCards/PageList.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'Kartu Kredit',
      },
    },
  },
]

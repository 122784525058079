import type { RouteRecordRaw } from 'vue-router'

const Navbar = () => import('@/components/TheNavbarTop.vue')

const routes: RouteRecordRaw[] = [
  {
    path: '',
    name: 'orders',
    components: {
      default: () => import('../views/Order/List/PageList.vue'),
      navbarTop: () => import('../views/Order/NavbarTop.vue'),
    },
    props: {
      navbarTop: {
        bgClass: 'bg-primary-500 text-white',
      },
    },
  },
  {
    path: '/order/:id',
    name: 'order detail',
    components: {
      default: () => import('../views/Order/Shopping/Show/PageIndex.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'Detail Pesanan',
      },
      default: true,
    },
  },
  {
    path: '/order/:id/request-cancel',
    name: 'order request cancel',
    components: {
      default: () => import('../views/Order/Shopping/RequestCancel.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'Pembatalan Pesanan',
      },
      default: true,
    },
  },
  {
    path: '/order/:id/complaint',
    name: 'order complaint',
    components: {
      default: () => import('../views/Order/Shopping/PageComplaint.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'Komplain Pesanan',
      },
      default: true,
    },
  },
]

export default routes

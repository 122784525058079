/**
 * Format number with thousand separator.
 * e.g 2500000 to "2.500.000"
 */
export const number = (num: string | number | bigint | null) => {
  if (typeof num === 'string') {
    num = parseInt(num)
  }

  if (typeof num === 'number' || typeof num === 'bigint') {
    return new Intl.NumberFormat('id-ID').format(num)
  }

  return '0'
}
export const padNumber = (value: number, pad = 2, fill = '0') => {
  return String(value).padStart(pad, fill)
}

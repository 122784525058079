const clickOutside = () => {
  let clickOutsideEvent: (event: Event) => void
  const created = function (el: HTMLElement, binding: { value: unknown }) {
    clickOutsideEvent = function (event) {
      // check if click is outside the el and his children
      if (!(el == event.target || el.contains(event.target as Node))) {
        // call method provided in attribute value
        if (typeof binding.value === 'function') {
          binding.value(event)
        }
      }
    }
    setTimeout(() => {
      window.addEventListener('mousedown', clickOutsideEvent)
    }, 20)
  }
  const unmounted = function () {
    window.removeEventListener('mousedown', clickOutsideEvent)
  }
  return {
    created,
    unmounted,
  }
}

export default clickOutside()

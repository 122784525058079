import { defineStore } from 'pinia'

interface State {
  complain_period: number
  banner_auto_slider_duration: number
  template_share_product_body: string
  template_share_product_has_promo_body: string
  template_share_product_title: string
  phone_pln: string
  phone_whatsapp: string
}

export const useSystemSettingsStore = defineStore('SystemSettings', {
  state: (): State => ({
    banner_auto_slider_duration: 3,
    complain_period: 3,
    template_share_product_body: '',
    template_share_product_has_promo_body: '',
    template_share_product_title: '',
    phone_pln: '',
    phone_whatsapp: '',
  }),
  getters: {
    bannerDuration(): number {
      if (this.banner_auto_slider_duration) {
        return this.banner_auto_slider_duration * 1000
      }
      return 5000
    },
  },
  actions: {},
})

<script setup lang="ts">
  import { ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { debounce } from '@/functions'

  const route = useRoute()
  const router = useRouter()
  const searchText = ref(route.query.s)
  const submitSearch = () => {
    sessionStorage.removeItem('page_partner_search')
    router.push({ name: 'partner search', query: { s: searchText.value } })
  }

  const onInput = debounce(submitSearch, 1000)
</script>

<template>
  <form @submit.prevent="submitSearch">
    <div class="flex flex-1 items-center whitespace-nowrap rounded-md border bg-white px-4 py-1">
      <fa-icon icon="i-fas-magnifying-glass" class="mr-4 text-gray-400"></fa-icon>
      <input
        v-model="searchText"
        class="w-full py-1 text-sm text-gray-600 focus:outline-none"
        placeholder="Cari Produk"
        type="search"
        @input="onInput()"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps<{
    message?: string | string[]
  }>()

  const text = computed(() => {
    // null / empty string / empty array
    if (!props.message || props.message.length === 0) {
      return null
    }

    // array
    if (Array.isArray(props.message)) {
      return props.message[0]
    }

    // string
    return props.message
  })
</script>

<template>
  <label v-if="text" class="block text-xs text-red-500">
    {{ text }}
  </label>
</template>

<style lang="postcss" scoped></style>

<script setup lang="ts">
  import { usePartnerApp } from '@/model/partner'
  import { usePartnerStore } from '@/stores/partner'

  const partner = usePartnerStore()

  const { redirectToPartnerRoutes } = usePartnerApp()

  if (partner.user) {
    redirectToPartnerRoutes()
  }
</script>

<template>
  <div>
    <router-view name="navbarTop"></router-view>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component"></component>
      </transition>
    </router-view>
  </div>
</template>

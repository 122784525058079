<script setup lang="ts">
  import { computed } from 'vue'
  import { fmt } from '@/functions/formatters'

  const props = defineProps<{
    id?: string
    modelValue: string
    type?: string
    label?: string
    placeholder?: string
    required?: boolean
    error?: string[]
    maxLength?: number
    rows?: number
    disabled?: boolean
    autocomplete?: string
    readonly?: boolean
    inputmode?: 'search' | 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal'
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void
  }>()

  const value = computed({
    set: (val) => {
      emit('update:modelValue', val)
    },
    get: () => props.modelValue,
  })

  const onInput = (event: Event) => {
    const eventTarget = event.target as HTMLInputElement
    emit('update:modelValue', eventTarget.value)
  }

  // https://stackoverflow.com/questions/10726909
  const randomString = Math.random().toString(36).slice(2)
  const inputId = computed(() => props.id ?? randomString)
</script>

<template>
  <div :class="{ 'has-error': !!error }">
    <label v-if="label" :for="inputId" class="form-label">
      {{ label }}
      <span v-if="required" class="text-red-600">*</span>
    </label>
    <div class="relative flex items-center">
      <textarea
        v-if="type === 'textarea'"
        :id="inputId"
        class="form-input min-h-24"
        :disabled="disabled"
        :value="value"
        :placeholder="placeholder"
        :rows="rows ?? 3"
        :required="required"
        :readonly="readonly"
        :maxlength="maxLength"
        @input="onInput"
      ></textarea>
      <input
        v-else
        :id="inputId"
        class="form-input"
        :disabled="disabled"
        :autocomplete="autocomplete ?? 'off'"
        :type="type"
        :value="value"
        :placeholder="placeholder"
        :required="required"
        :maxlength="maxLength"
        :readonly="readonly"
        :inputmode="inputmode"
        @input="onInput"
      />
      <div
        v-if="maxLength !== undefined"
        class="absolute bottom-1 right-2 bg-white text-3xs text-gray-500"
      >
        {{ fmt.number(maxLength - value.length) }}
      </div>
    </div>
    <g-error-msg :message="error"></g-error-msg>
  </div>
</template>

<style lang="postcss" scoped>
  .has-error .form-input {
    @apply border-red-500;
  }
  .has-error .form-label {
    @apply text-red-500;
  }
</style>

<script setup lang="ts">
  // import { initMidtrans } from '@/components/Payment/InitMidtrans.js'
  import HomePopup from '@/components/TheHomePopup.vue'
  import NavbarBottom from '@/components/TheNavbarBottom.vue'
  import { useShopInit } from '@/model/shop'
  import { usePageStore } from '@/stores/page'
  import { usePartnerStore } from '@/stores/partner'
  import NavbarBottomPartner from '@/views-partner/components/the-navbar-bottom-partner.vue'
  import Error404 from './Errors/ErrorNotFound.vue'

  const page = usePageStore()
  const partner = usePartnerStore()

  const { initShop } = useShopInit()

  initShop()
  // initMidtrans()
</script>

<template>
  <error-404 v-if="page.showError404"></error-404>
  <div v-else-if="page.shopInitialized">
    <teleport to="#navbar-bottom">
      <navbar-bottom v-if="!partner.user"></navbar-bottom>
      <navbar-bottom-partner v-else></navbar-bottom-partner>
    </teleport>

    <div id="content" class="container mb-20 mt-20 px-4">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component"></component>
        </transition>
      </router-view>
    </div>
    <home-popup v-if="!partner.user"></home-popup>
  </div>
</template>

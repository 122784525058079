import { defineStore } from 'pinia'
import { useApiStore } from '@/stores/api'

interface State {
  order: ShopOrderDetail | null
  loading: boolean
}

export const useDetailOrderStore = defineStore('DetailOrder', {
  state: (): State => ({
    order: null,
    loading: false,
  }),
  getters: {},
  actions: {
    loadOrder(id: string | number) {
      const api = useApiStore()
      this.loading = true
      this.order = null
      return new Promise((resolve, reject) => {
        api
          .GET<ShopOrderDetail>('shop-order/' + id)
          .then((response) => {
            this.order = response
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    updateOrder(newOrder: ShopOrderDetail) {
      this.order = { ...this.order, ...newOrder }
    },
    updateOrderStatus(newStatus: string) {
      if (this.order) {
        this.order.order_status = newStatus
      }
    },
  },
})

import type { RouteRecordRaw } from 'vue-router'

const Navbar = () => import('@/components/TheNavbarTop.vue')

const routes: RouteRecordRaw[] = [
  {
    path: '/produk/bundle',
    name: 'product bundle list',
    components: {
      default: () => import('@/views/ProductBundle/PageList.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'Daftar Paket',
      },
    },
  },
  {
    path: '/produk/:id',
    name: 'product',
    props: true,
    components: {
      default: () => import('../views/Product/PageIndex.vue'),
      navbarTop: () => import('../views/Product/NavbarTop.vue'),
    },
  },
  {
    path: '/produk/:id/ulasan',
    name: 'product review',
    components: {
      default: () => import('../views/Product/ProductReview.vue'),
      navbarTop: Navbar,
    },
    props: {
      default: true,
      navbarTop: {
        title: 'Ulasan Pembeli',
      },
    },
  },
]

export default routes

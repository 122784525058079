<script setup lang="ts">
  import IconChat from '@/components/AppNavbarIconChat.vue'
  import IconFavorite from '@/components/AppNavbarIconFavorite.vue'
  import IconNotification from '@/components/AppNavbarIconNotification.vue'
  import { useBack } from '@/functions/composables/back'
  import { useUserStore } from '@/stores/user'

  const user = useUserStore()

  defineProps({
    title: {
      type: String,
      default: '',
    },
    bgClass: {
      type: String,
      default: 'bg-white',
    },
  })

  const { back } = useBack()
</script>

<template>
  <div id="navbar" class="navbar-top" :class="bgClass">
    <div class="container flex h-16 items-center px-4 py-2">
      <slot>
        <div class="-ml-2">
          <button
            aria-label="Back"
            class="pointer-events-auto relative mr-2 flex items-center justify-center p-4 text-primary-500"
            @click="back()"
          >
            <fa-icon icon="i-fas-chevron-left" class="absolute"></fa-icon>
          </button>
        </div>
        <h1 class="font-semibold">
          {{ title }}
        </h1>
        <div class="-mr-1 ml-auto flex space-x-1 text-gray-500">
          <icon-favorite v-if="user.isLoggedIn"></icon-favorite>
          <icon-chat></icon-chat>
          <icon-notification v-if="user.isLoggedIn"></icon-notification>
        </div>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
  // https://vite-plugin-pwa.netlify.app/frameworks/vue.html#periodic-sw-updates
  import { useRegisterSW } from 'virtual:pwa-register/vue'
  import { computed, ref, watch } from 'vue'
  import AppModal from '@/components/AppModal.vue'

  const { needRefresh, updateServiceWorker } = useRegisterSW()

  const updating = ref(false)
  const modal = ref<InstanceType<typeof AppModal>>()

  const btnUpdateClicked = async () => {
    updating.value = true
    try {
      await updateServiceWorker()
    } catch (error) {
      window.location.reload()
    }
  }

  const LOCALSTORAGE_KEY = 'update_prompt_shown_at'
  const ASK_AGAIN_AFTER = 24 * 60 * 60 * 1000 // 24 hours in miliseconds

  const shouldShow = computed(() => {
    if (!needRefresh.value) {
      return false
    }

    return getLastUpdatePrompt() + ASK_AGAIN_AFTER < Date.now()
  })

  watch(shouldShow, (newValue) => {
    if (newValue) {
      showPrompt()
    }
  })

  const updateLastUpdatePrompt = () => {
    localStorage.setItem(LOCALSTORAGE_KEY, Date.now().toString())
  }
  const getLastUpdatePrompt = () => {
    return Number(localStorage.getItem(LOCALSTORAGE_KEY))
  }

  const showPrompt = () => {
    modal.value?.open()
  }
  const closePrompt = () => {
    modal.value?.close()
  }
</script>

<template>
  <app-modal ref="modal" @closed="updateLastUpdatePrompt">
    <div class="mb-4 text-center">Update Tersedia</div>
    <template #footer>
      <div class="flex justify-center gap-2">
        <g-button
          class="btn py-2 text-xs"
          :disabled="updating"
          :class="{ 'cursor-not-allowed': updating }"
          @click="closePrompt"
        >
          Lain kali
        </g-button>
        <g-button
          class="btn btn-primary flex items-center py-2 text-xs"
          :loading="updating"
          :class="{ 'cursor-not-allowed': updating }"
          @click="btnUpdateClicked"
        >
          Update
        </g-button>
      </div>
    </template>
  </app-modal>
</template>

<style lang="postcss" scoped></style>

import type { App } from 'vue'
import AppButton from './AppButton.vue'
import AppErrorMsg from './AppErrorMessage.vue'
import AppImage from './AppImage.vue'
import AppInput from './AppInput.vue'
import AppSelect from './AppSelect.vue'
import FaIcon from './FaIcon.vue'

export default {
  install(Vue: App) {
    Vue.component('GButton', AppButton)
    Vue.component('GErrorMsg', AppErrorMsg)
    Vue.component('GImg', AppImage)
    Vue.component('GInput', AppInput)
    Vue.component('GSelect', AppSelect)
    Vue.component('FaIcon', FaIcon)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    GButton: typeof AppButton
    GErrorMsg: typeof AppErrorMsg
    GImg: typeof AppImage
    GInput: typeof AppInput
    GSelect: typeof AppSelect
    FaIcon: typeof FaIcon
  }
}

import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import { usePageStore } from '@/stores/page'
import LayoutApp from '../views/LayoutApp.vue'
import LayoutAuth from '../views/LayoutAuth.vue'
import LayoutShop from '../views/LayoutShop.vue'
import Auth from './auth'
import Category from './category'
import Mscash from './mscash'
import Order from './order'
import partnerRoutes from './partner'
import product from './product'
import productDigital from './product-digital'
import User from './user'

const Navbar = () => import('../components/TheNavbarTop.vue')

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: LayoutApp,
    children: [
      {
        path: '/',
        component: LayoutShop,
        children: [
          {
            path: '',
            name: 'home',
            components: {
              default: () => import('../views/Home/PageHome.vue'),
              navbarTop: () => import('../views/Home/NavbarTop.vue'),
            },
          },
          {
            path: '/cart',
            name: 'cart',
            components: {
              default: () => import('../views/Cart/PageList.vue'),
              navbarTop: Navbar,
            },
            props: {
              navbarTop: {
                title: 'Keranjang',
              },
            },
          },
          {
            path: '/checkout',
            name: 'checkout',
            component: () => import('../views/Checkout/PageIndex.vue'),
          },
          {
            path: '/notification',
            name: 'notification',
            components: {
              default: () => import('../views/Notification/PageList.vue'),
              navbarTop: Navbar,
            },
            props: {
              navbarTop: {
                title: 'Notifikasi',
              },
            },
          },
          {
            path: '/changelog',
            name: 'changelog',
            components: {
              default: () => import('../views/Changelog/PageIndex.vue'),
              navbarTop: Navbar,
            },
            props: {
              navbarTop: {
                title: 'Riwayat Update',
              },
            },
          },
          {
            path: '/search',
            name: 'search',
            components: {
              default: () => import('../views/Search/PageIndex.vue'),
              navbarTop: () => import('../views/Product/NavbarTop.vue'),
            },
          },
          {
            path: '/searchgroup/:type',
            name: 'searchgroup',
            components: {
              default: () => import('../views/Search/PageGroup.vue'),
              navbarTop: () => import('../views/Product/NavbarTop.vue'),
            },
          },
          {
            path: '/promo',
            name: 'promo',
            components: {
              default: () => import('../views/Promo/PageIndex.vue'),
              navbarTop: Navbar,
            },
            props: {
              navbarTop: {
                title: 'Promo',
              },
            },
          },
          {
            path: '/promo/produk/:id',
            name: 'promo detail',
            components: {
              default: () => import('../views/Promo/Product/PageShow.vue'),
              navbarTop: Navbar,
            },
            props: {
              navbarTop: {
                title: 'Promo Produk',
              },
              default: true,
            },
          },
          {
            path: '/promo/voucher/:id',
            name: 'voucher detail',
            components: {
              default: () => import('../views/Promo/Voucher/PageShow.vue'),
              navbarTop: Navbar,
            },
            props: {
              navbarTop: {
                title: 'Voucher Promo',
              },
              default: true,
            },
          },
          {
            path: 'favoritku',
            name: 'favorite product',
            components: {
              default: () => import('../views/Favorite/PageIndex.vue'),
              navbarTop: Navbar,
            },
            props: {
              navbarTop: {
                title: 'Favorit',
              },
            },
          },
          {
            path: 'ulasanku',
            components: {
              default: () => import('../views/Review/PageIndex.vue'),
              navbarTop: Navbar,
            },
            props: {
              navbarTop: {
                title: 'Ulasan Saya',
              },
            },
            children: [
              {
                path: '',
                name: 'review queue',
                component: () => import('../views/Review/PageQueue.vue'),
              },
              {
                path: 'riwayat',
                name: 'review history',
                component: () => import('../views/Review/PageHistory.vue'),
              },
            ],
          },
        ],
      },
      {
        path: '/category',
        component: LayoutShop,
        children: Category,
      },
      {
        path: '/user',
        component: LayoutShop,
        children: User,
      },
      {
        path: '/orders',
        component: LayoutShop,
        children: Order,
      },
      {
        path: '/mscash',
        component: LayoutShop,
        children: Mscash,
      },
      {
        path: '/produk/digital',
        component: LayoutShop,
        children: productDigital,
      },
      {
        path: '/produk',
        component: LayoutShop,
        children: product,
      },
    ],
  },
  ...partnerRoutes,
  {
    path: '/',
    component: LayoutAuth,
    children: Auth,
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('../views/Errors/ErrorMaintenance.vue'),
  },
  /* Temporary Redirect After change 'product' to 'produk' */
  {
    path: '/product/:id',
    redirect: (to) => {
      return { name: 'product', params: to.params }
    },
  },
  {
    path: '/product/:id/review',
    redirect: (to) => {
      return { name: 'product review', params: to.params, query: to.query }
    },
  },
  {
    path: '/product-bundle',
    redirect: (to) => {
      return { name: 'product bundle list', query: to.query }
    },
  },
  /* End redirect */
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/Errors/ErrorNotFound.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const page = usePageStore()

  page.resetErrorState()

  next()
})

export default router

import { startFCM } from '@/firebase'
import { useApiStore } from '@/stores/api'
import { useCartStore } from '@/stores/cart'
import { useCartBundleStore } from '@/stores/cart-bundle'
import { usePageStore } from '@/stores/page'
import { usePartnerStore } from '@/stores/partner'
import { useSystemSettingsStore } from '@/stores/systemSettings'
import { useUserStore } from '@/stores/user'
import { usePartnerApp, usePartnerCart } from './partner'

type ShopInitAddressResponse = {
  id: number
  company_id: number
  label: string
  address: string
  additional_fee_label: string | null
  additional_fee_value: number | null
  additional_fee_is_percent: number | null
  region_id: number
  region: {
    id: number
    name: string
  }
}

type ShopInitResponse = {
  system_settings: {
    complain_period: number
    banner_auto_slider_duration: number
    template_share_product_body: string
    template_share_product_has_promo_body: string
    template_share_product_title: string
    phone_pln: string
    phone_whatsapp: string
  }
  user?: {
    id: number
    name: string
    email: string
    employee: {
      id: number
      user_id: number
      company_id: number
      current_credits: number
      points: number
      mscash: number
      points_expired_at: string
      credits_reset_at: string
      company: {
        id: number
        name: string
        prefix: string | null
        full_name: string
        addresses: ShopInitAddressResponse[]
      }
    }
    shopping_carts: ProductCartItem[]
    shopping_cart_bundles: ShoppingCartBundle[]
    count_notifications: number
  }
}

export function useShopInit() {
  const api = useApiStore()
  const user = useUserStore()
  const cart = useCartStore()
  const cartBundle = useCartBundleStore()
  const page = usePageStore()
  const systemSettings = useSystemSettingsStore()
  const partner = usePartnerStore()
  const { redirectToPartnerRoutes, redirectToRegularRoutes } = usePartnerApp()

  type PartnerShopInitResponse = {
    partner: Partner
    user: PartnerCustomer
    system_settings: PartnerSystemSettings
  }

  const initShop = async () => {
    const response = await api.GET<ShopInitResponse | PartnerShopInitResponse>('shop/init')

    if ('partner' in response) {
      startFCM()
      partner.initPartner(response.partner)
      partner.initUser(response.user)
      partner.initSystemSettings(response.system_settings)
      page.setShopInitialized()
      redirectToPartnerRoutes()

      const { getPartnerCarts } = usePartnerCart()
      getPartnerCarts()
      return
    }

    redirectToRegularRoutes()

    if (response.user) {
      // logged in
      startFCM()
      user.setShopInitResponse(response.user)
      cart.setItems(response.user.shopping_carts)
      cartBundle.setCarts(response.user.shopping_cart_bundles)
      cartBundle.markCheckoutItemsAsSelected()
      cart.markCheckoutItemsAsSelected()
    } else {
      // guest
      cart.setItems([])
      cartBundle.setCarts([])
    }
    systemSettings.$state = response.system_settings
    page.setShopInitialized()
  }

  return {
    initShop,
  }
}

import type { RouteRecordRaw } from 'vue-router'

const Navbar = () => import('../views/Product/NavbarTop.vue')

const routes: RouteRecordRaw[] = [
  {
    path: '',
    name: 'category',
    props: true,
    components: {
      default: () => import('../views/Category/PageIndex.vue'),
      navbarTop: Navbar,
    },
  },
  {
    path: ':id',
    name: 'category detail',
    components: {
      default: () => import('../views/Category/PageDetail.vue'),
      navbarTop: Navbar,
    },
    props: {
      default: true,
    },
  },
]

export default routes

<script setup lang="ts">
  // https://vite-pwa-org.netlify.app/frameworks/vue.html#type-declarations
  import { useRegisterSW } from 'virtual:pwa-register/vue'
  import { computed } from 'vue'
  import { usePartnerStore } from '@/stores/partner'

  const { needRefresh } = useRegisterSW()

  const partner = usePartnerStore()

  const countCarts = computed(() => {
    return partner.carts.reduce((carry, cart) => carry + cart.qty, 0)
  })
</script>

<template>
  <div class="flex justify-center space-x-4 text-gray-500">
    <router-link
      exact-active-class="text-primary-500"
      :to="{ name: 'partner home' }"
      class="navbar-bottom-item"
    >
      <fa-icon icon="i-fas-house" class="text-lg"></fa-icon>
      <div class="leading-0 text-xxs">Home</div>
    </router-link>
    <router-link
      active-class="text-primary-500"
      :to="{ name: 'partner cart' }"
      class="navbar-bottom-item"
    >
      <div class="relative inline-block">
        <fa-icon icon="i-fas-cart-shopping" class="text-lg"></fa-icon>
        <span class="shopping-cart-counter">
          {{ countCarts }}
        </span>
      </div>
      <div class="leading-0 text-xxs">Keranjang</div>
    </router-link>
    <router-link
      active-class="text-primary-500"
      :to="{ name: 'partner order list' }"
      class="navbar-bottom-item"
    >
      <fa-icon icon="i-fas-right-left" class="text-lg"></fa-icon>
      <div class="leading-0 text-xxs">Transaksi</div>
    </router-link>
    <router-link
      active-class="text-primary-500"
      :to="{ name: 'partner account' }"
      class="navbar-bottom-item"
    >
      <div class="relative inline-block">
        <fa-icon icon="i-fas-user" class="text-lg"></fa-icon>
        <div
          v-if="needRefresh"
          class="absolute right-2 top-0 h-2 w-2 rounded-full bg-red-500"
        ></div>
        <div class="leading-0 whitespace-nowrap text-xxs">Akun Saya</div>
      </div>
    </router-link>
  </div>
</template>

<style lang="postcss" scoped>
  .shopping-cart-counter {
    @apply absolute rounded-full bg-red-500 leading-none text-white;
    @apply right-[-0.6rem] top-[-5px] px-[0.4rem] py-1 text-[0.55rem];
  }
  .navbar-bottom-item {
    @apply block max-w-[5rem] flex-1 pb-1 pt-2 text-center;
  }
</style>

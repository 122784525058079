const usedWholesalePrice = (product: ProductCalculatePrice, qty: number) => {
  const wholesalePrices = product.wholesale_prices ?? []
  const prices = wholesalePrices.filter((p) => p.min_qty <= qty)

  if (prices.length === 0) {
    return null
  }

  return prices.sort((a, b) => a.min_qty - b.min_qty).pop()
}

export const productDiscountValue = (product: ProductCalculatePrice) => {
  if (!product.promo) {
    return 0
  }

  if (product.promo.stock === 0) {
    return 0
  }

  if (product.promo.value_cash) {
    return product.promo.value_cash
  } else if (product.promo.value_percentage) {
    return Math.round((product.retail_price * product.promo.value_percentage) / 100)
  }

  return 0
}

export const productFinalPrice = (product: ProductCalculatePrice, qty: number) => {
  // if qty is greater than wholesale prices smallest min_qty
  // return nearest below wholesale prices unit_price
  const wholesalePrice = usedWholesalePrice(product, qty)
  if (wholesalePrice) {
    return wholesalePrice.unit_price
  }

  // if no wholesale prices found, return retail price minus discount
  const finalPrice = product.retail_price - productDiscountValue(product)

  return Math.max(finalPrice, 0) // return 0 if finalPrice is negative
}

export const maxPurchase = (product: ProductCalculateMaxPurchase) => {
  // if no promo, then max Purchase equals to product stock
  // if product stock is null, then product is always ready

  const stock = product.stock ?? Number.MAX_SAFE_INTEGER
  const promo = product.promo

  if (!promo) {
    return stock
  }

  // if product has promo, then max Purchase equals to the smallest value of:
  // product stock, promo stock, promo max purchase

  // if promo stock or promo max purchase is 0, and promo.allow_over_purchase is true,
  // then max Purchase equals to product stock

  const promoStock = promo.stock ?? Number.MAX_SAFE_INTEGER
  let promoMaxPurchase = Number.MAX_SAFE_INTEGER

  if (promo.max_purchase !== null && promo.purchased !== undefined) {
    promoMaxPurchase = promo.max_purchase - promo.purchased
  }

  if (promo.allow_over_purchase === true && (promoMaxPurchase === 0 || promoStock === 0)) {
    return stock
  }

  return Math.min(stock, promoStock, promoMaxPurchase)
}

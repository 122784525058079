import { format, formatDistanceToNow, isSameDay, isSameMonth, isSameYear } from 'date-fns/esm'
import { id } from 'date-fns/esm/locale'

export const date = (dt: unknown, fmt = 'd MMM yyyy') => {
  if (typeof dt !== 'number' && typeof dt !== 'string' && !(dt instanceof Date)) {
    return '-'
  }

  return format(new Date(dt), fmt, { locale: id })
}

export const dateRelative = (dt: unknown, addSuffix = true) => {
  if (typeof dt !== 'number' && typeof dt !== 'string' && !(dt instanceof Date)) {
    return '-'
  }

  return formatDistanceToNow(new Date(dt), { locale: id, addSuffix })
}

export const dateRange = (start: number | Date, end: number | Date) => {
  // Same day => 30 Jun 2021
  // Same month => 1 - 30 Jun 2021
  // Different month same year => 1 Mar - 15 Mei 2021
  // Different year => 15 Des 2021 - 15 Jan 2022

  let formatStart = ''
  const formatEnd = 'd MMM yyyy'
  if (isSameDay(start, end)) {
    return date(start, formatEnd)
  }

  if (!isSameYear(start, end)) {
    formatStart = formatEnd
  } else if (!isSameMonth(start, end)) {
    formatStart = 'd MMM'
  } else {
    formatStart = 'd'
  }

  return date(start, formatStart) + ' - ' + date(end, formatEnd)
}

export const getCountdownValue = (endDate: string | number | Date) => {
  return Math.max(new Date(endDate).getTime() - new Date().getTime(), 0)
}
const oneDay = 24 * 60 * 60 * 1000
export const lessThanOneDay = (endDate: string | number | Date) => {
  return endDate && getCountdownValue(endDate) < oneDay
}

import { ref } from 'vue'
import { useApiStore } from '@/stores/api'
import { useCartBundleStore } from '@/stores/cart-bundle'
import { useToastStore } from '@/stores/toast'

export function useShoppingCartBundleAddOrRemove() {
  const api = useApiStore()
  const toast = useToastStore()
  const cartBundle = useCartBundleStore()

  const submitting = ref(false)

  const submitAdd = async (id: number, form: object) => {
    try {
      submitting.value = true
      const response = await api.POST<ApiResponse<ShoppingCartBundle>>(
        `shopping-cart-bundle/${id}`,
        form,
      )
      cartBundle.setCarts(response.data)
    } catch (error) {
      toast.add('Data tidak berhasil disimpan')
    } finally {
      submitting.value = false
    }
  }

  const submitRemove = (id: number) => {
    return new Promise<void>((resolve, reject) => {
      submitting.value = true
      api
        .DELETE<ApiResponse<ShoppingCartBundle>>(`shopping-cart-bundle/${id}`)
        .then((response) => {
          cartBundle.setCarts(response.data)
          resolve()
        })
        .catch((error) => {
          toast.add('Data tidak berhasil disimpan')
          reject(error)
        })
        .finally(() => {
          submitting.value = false
        })
    })
  }

  return {
    submitting,
    submitAdd,
    submitRemove,
  }
}

<script setup lang="ts">
  import { onBeforeUnmount, onMounted } from 'vue'
  import { getImage } from '@/functions/composables/errorpage'

  onMounted(() => {
    const el = document.getElementById('navbar-bottom-wrapper')
    if (el) {
      el.classList.add('hidden')
    }
  })
  onBeforeUnmount(() => {
    const el = document.getElementById('navbar-bottom-wrapper')
    if (el) {
      el.classList.remove('hidden')
    }
  })
</script>

<template>
  <div
    class="min-h-screen bg-cover bg-left px-4 pt-24 sm:flex sm:items-center sm:justify-center sm:p-0 lg:bg-center"
    :style="{ backgroundImage: 'url(' + getImage('login.jpg') + ')' }"
  >
    <div class="container relative">
      <div class="absolute inset-x-0 -top-10 sm:-top-12">
        <router-link
          :to="{ name: 'home' }"
          class="mx-auto block h-20 w-20 overflow-hidden rounded-full bg-white p-1 shadow sm:h-24 sm:w-24"
        >
          <img
            src="/img/icons/icon-96x96.png"
            alt="logo msshop"
            class="h-full w-full rounded-full"
          />
        </router-link>
      </div>
      <div
        class="mx-auto overflow-hidden rounded-lg bg-white px-6 pb-8 pt-16 text-gray-600 shadow-md"
      >
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component"></component>
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
  :deep() {
    .form-input {
      @apply rounded px-4 py-2 text-sm text-gray-700 focus:outline-none;
    }
    .form-label {
      @apply font-medium;
    }
  }
</style>

import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { getOperatorFromNumber, getPulsaCategoryId } from '@/functions/getMobileOperator'
import { useApiStore } from '@/stores/api'
import { useCheckoutDigitalStore } from '@/stores/checkoutDigital'

interface ProductDigitalCategory {
  products: DigitalProduct[]
}

interface ProductDigitalCategory {
  id: number
  parent_id: number
  name: string
  url_icon: string | null
  full_name: string
  children?: { id: number }[]
  children_count?: number
}

export const CATEGORY_ID = {
  PULSA_OPERATOR: 1,
  PAKET_INTERNET: 100,
  PLN: 200,
  PLN_TOKEN: 201,
  // GAME: 300,
  // E_WALLET: 400,
  GAME: -1,
  E_WALLET: -1,
}

export function useProductDigitalList() {
  const api = useApiStore()
  const categories = ref<ProductDigitalCategory[]>([])
  const getList = async () => {
    const response = await api.GET<ApiResponse<ProductDigitalCategory>>('digital-product-category')
    categories.value = response.data
  }

  getList()

  const pulsa = computed(() => categories.value.find((c) => c.id === CATEGORY_ID.PULSA_OPERATOR))
  const internet = computed(() => categories.value.find((c) => c.id === CATEGORY_ID.PAKET_INTERNET))
  const pln = computed(() => categories.value.find((c) => c.id === CATEGORY_ID.PLN))
  const game = computed(() => categories.value.find((c) => c.id === CATEGORY_ID.GAME))
  const eWallet = computed(() => categories.value.find((c) => c.id === CATEGORY_ID.E_WALLET))

  return {
    pulsa,
    internet,
    pln,
    game,
    eWallet,
  }
}

export function usePulsaList() {
  const route = useRoute()
  const api = useApiStore()
  const checkoutDigital = useCheckoutDigitalStore()
  const products = ref<DigitalProduct[]>([])
  const loading = ref(false)

  const pulsaCategoryId = computed(() => {
    const operatorName = getOperatorFromNumber(checkoutDigital.phone)
    return getPulsaCategoryId(operatorName)
  })

  const getList = async () => {
    try {
      loading.value = true
      if (pulsaCategoryId.value) {
        const response = await api.GET<ProductDigitalCategory>(
          'digital-product-category/' + pulsaCategoryId.value,
        )
        // TODO still show unavailable but disable the button
        products.value = response.products.filter((p) => p.available)

        if (route.query.produk as string) {
          const product = products.value.find((p) => p.id === Number(route.query.produk))
          if (product) {
            checkoutDigital.selectProduct(product)
          }
        }
      } else {
        products.value = []
      }
    } finally {
      loading.value = false
    }
  }

  watch(pulsaCategoryId, () => {
    getList()
  })

  onMounted(() => {
    checkoutDigital.$reset()
  })

  return {
    products,
    loading,
  }
}

export function useInternetList() {
  const route = useRoute()
  const api = useApiStore()
  const checkoutDigital = useCheckoutDigitalStore()
  const products = ref<DigitalProduct[]>([])
  const loading = ref(false)

  const differentWithPulsaId = 99
  const internetCategoryId = computed(() => {
    const operatorName = getOperatorFromNumber(checkoutDigital.phone)
    const id = getPulsaCategoryId(operatorName)
    return id ? id + differentWithPulsaId : id
  })

  const getList = async () => {
    try {
      loading.value = true
      if (internetCategoryId.value) {
        const response = await api.GET<ProductDigitalCategory>(
          'digital-product-category/' + internetCategoryId.value,
        )
        products.value = response.products

        if (route.query.produk as string) {
          const product = products.value.find((p) => p.id === Number(route.query.produk))
          if (product) {
            checkoutDigital.selectProduct(product)
          }
        }
      } else {
        products.value = []
      }
    } finally {
      loading.value = false
    }
  }

  watch(internetCategoryId, () => {
    getList()
  })

  onMounted(() => {
    checkoutDigital.$reset()
  })

  return {
    products,
    loading,
  }
}

export function usePlnList() {
  const route = useRoute()
  const api = useApiStore()
  const checkoutDigital = useCheckoutDigitalStore()

  const products = ref<DigitalProduct[]>([])
  const loading = ref(false)

  const getList = async () => {
    try {
      loading.value = true
      const response = await api.GET<ProductDigitalCategory>(
        `digital-product-category/${CATEGORY_ID.PLN_TOKEN}`,
      )
      products.value = response.products

      if (route.query.produk as string) {
        const product = products.value.find((p) => p.id === Number(route.query.produk))
        if (product) {
          checkoutDigital.selectProduct(product)
        }
      }
    } finally {
      loading.value = false
    }
  }

  getList()

  onMounted(() => {
    checkoutDigital.$reset()
  })

  return {
    products,
    loading,
  }
}

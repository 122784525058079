<script setup lang="ts">
  import { useRoute } from 'vue-router'
  import IconNotification from '@/components/AppNavbarIconNotification.vue'
  import IconChat from '@/views-partner/components/app-navbar-icon-chat.vue'

  const route = useRoute()
</script>

<template>
  <div id="navbar" class="navbar-top bg-primary-500 text-white">
    <div class="container flex h-16 items-center space-x-1 px-4 py-2">
      <slot>
        <h1 class="flex-1 font-semibold">
          {{ route.meta.title }}
        </h1>
      </slot>
      <icon-chat></icon-chat>
      <icon-notification></icon-notification>
    </div>
  </div>
</template>

import { defineStore } from 'pinia'
import whatsapp from '@/functions/composables/whatsapp'

type State = {
  partner: Partner | null
  user: PartnerCustomer | null
  system_settings: PartnerSystemSettings | null
  carts: PartnerCustomerCart[]

  cart_initiated: boolean
}

export const usePartnerStore = defineStore('Partner', {
  state: (): State => ({
    partner: null,
    user: null,
    system_settings: null,
    carts: [],

    cart_initiated: false,
  }),
  getters: {
    // get cart by partner product id
    findProductInCart: (state) => (partnerProductId: number) => {
      return state.carts.find((item) => item.partner_product_id === partnerProductId)
    },
    whatsappLinkManager: (state) => (text: string) => {
      if (state.partner) {
        return whatsapp(text, state.partner.whatsapp)
      }

      return ''
    },
    whatsappLinkAdmin: (state) => (text: string) => {
      if (state.system_settings) {
        return whatsapp(text, state.system_settings.phone_whatsapp)
      }

      return ''
    },
  },
  actions: {
    initPartner(partner: Partner) {
      this.partner = partner
    },
    initUser(user: PartnerCustomer) {
      this.user = user
    },
    initSystemSettings(systemSettings: PartnerSystemSettings) {
      this.system_settings = systemSettings
    },

    // address
    insertAddress(data: PartnerCustomerAddress) {
      if (this.user) {
        this.user.addresses.push(data)
      }
    },
    updateAddress(data: PartnerCustomerAddress) {
      if (this.user) {
        const index = this.user.addresses.findIndex((item) => item.id === data.id)
        if (index >= 0) {
          this.user.addresses[index] = data
        }
      }
    },
    removeAddress(id: number) {
      if (this.user) {
        this.user.addresses = this.user?.addresses.filter((item) => item.id !== id)
      }
    },

    // cart
    initCart(carts: PartnerCustomerCart[]) {
      this.carts = carts
      this.cart_initiated = true
    },
    insertItemToCart(partnerProductId: number, qty: number) {
      this.carts.push({
        partner_product_id: partnerProductId,
        qty,
        selected: true,
      })
    },
    removeItemFromCart(partnerProductId: number) {
      const index = this.carts.findIndex((item) => {
        return item.partner_product_id === partnerProductId
      })
      if (index >= 0) {
        this.carts.splice(index, 1)
      }
    },
    updateCart(partnerProductId: number, qty: number) {
      // update qty to 0 = remove from cart
      if (qty === 0) {
        this.removeItemFromCart(partnerProductId)
        return
      }

      // find index of product in cart
      const index = this.carts.findIndex((item) => {
        return item.partner_product_id === partnerProductId
      })

      // product already in cart, update qty
      if (index >= 0) {
        this.carts[index].qty = qty
        return
      }

      // product not in cart, insert new data
      this.insertItemToCart(partnerProductId, qty)
    },
    clearCart() {
      this.carts = []
    },
    toggleSelectCart(value: boolean, ids: number[]) {
      this.carts.forEach((item) => {
        if (ids.includes(item.partner_product_id)) {
          item.selected = value
        }
      })
    },
  },
})

import { defineStore } from 'pinia'
import { productFinalPrice } from '@/functions/composables/product'
import { useApiStore } from '@/stores/api'
import { useUserStore } from '@/stores/user'

interface StateItem extends ProductCartItem {
  selected: boolean
}
interface State {
  items: StateItem[]
  isInitiated: boolean
}

export const useCartStore = defineStore('Cart', {
  state: (): State => ({
    items: [],
    isInitiated: false,
  }),
  getters: {
    findItem() {
      return (productId: string | number): StateItem | undefined =>
        this.items.find((item) => item.product.id === parseInt(productId + ''))
    },
    selected(): ProductCartItem[] {
      return this.items.filter((item) => item.selected) ?? []
    },
    productFinalPrice() {
      return (item: { product: ProductCalculatePrice; qty: number }): number =>
        productFinalPrice(item.product, item.qty)
    },
    total(): number {
      return this.items
        .filter((item) => item.selected)
        .reduce((carry, item) => this.productFinalPrice(item) * item.qty + carry, 0)
    },
  },
  actions: {
    setItems(items: ProductCartItem[]) {
      // get from localStorage.cartItems
      const storageItems = JSON.parse(
        localStorage.getItem('cart_items') ?? '[]',
      ) as ProductCartItem[]

      // add property selected
      this.items = items.map((item) => ({ ...item, selected: false }))

      storageItems.forEach((item) => {
        const findItem = this.findItem(item.product_id)

        if (!findItem) {
          // if item does not exist in localStorage, push to items, send PUT request shopping-cart
          this.addItem(item.product, item.qty)
        } else if (findItem.qty !== item.qty) {
          // if item exist in localstorage, update the qty, send PUT request shopping-cart
          this.editItemQty(item.product.id, item.qty)
        }
      })

      this.updateLocalStorageCartItems()

      this.isInitiated = true
    },
    getCheckoutItems() {
      try {
        return JSON.parse(localStorage.getItem('checkout_items') ?? '[]') as {
          product: ProductCart
          qty: number
        }[]
      } catch (error) {
        return []
      }
    },
    markCheckoutItemsAsSelected() {
      const checkoutItems = this.getCheckoutItems()
      checkoutItems.forEach((item) => {
        const findItem = this.findItem(item.product.id)
        if (findItem) {
          findItem.selected = true
        }
      })
      this.updateLocalStorageCheckoutItems()
    },
    updateLocalStorageCheckoutItems() {
      // saves item id and qty to local storage, will be used in checkout page
      const json = JSON.stringify(this.selected)
      localStorage.setItem('checkout_items', json)
    },
    deselectAll() {
      this.items?.forEach((item) => {
        item.selected = false
      })
    },
    selectAll() {
      this.items
        .filter((item) => !item.product.deleted)
        .forEach((item) => {
          item.selected = true
        })
    },
    addItem(product: ProductCart, qty: number) {
      const newItem: StateItem = {
        id: 0,
        qty,
        product_id: product.id,
        product,
        selected: true,
      }
      this.items.push(newItem)

      return new Promise((resolve, reject) => {
        const user = useUserStore()
        const api = useApiStore()
        if (user.isLoggedIn) {
          api
            .POST('shopping-cart/' + product.id, { qty })
            .then((response) => {
              this.setItems(response as ProductCartItem[])
              const find = this.findItem(product.id)
              if (find) {
                find.selected = true
              }
              this.updateLocalStorageCartItems()
              resolve(response)
            })
            .catch((error) => {
              console.log(error)
              reject(error)
            })
          return
        }
        resolve(newItem)
        this.updateLocalStorageCartItems()
      })
    },
    editItemQty(productId: number, qty: number) {
      const find = this.findItem(productId)
      if (find) {
        find.qty = qty
      }
      return new Promise((resolve, reject) => {
        const user = useUserStore()
        const api = useApiStore()
        if (user.isLoggedIn) {
          api
            .PUT('shopping-cart/' + productId, { qty })
            .then((response) => {
              this.updateLocalStorageCartItems()
              resolve(response)
            })
            .catch((error) => {
              console.log(error)
              reject(error)
            })
          return
        }
        this.updateLocalStorageCartItems()
        resolve(true)
      })
    },
    deleteItem(productId: number) {
      this.items = this.items.filter((item) => item.product_id !== productId)
      return new Promise((resolve, reject) => {
        const user = useUserStore()
        const api = useApiStore()
        if (user.isLoggedIn) {
          api
            .DELETE('shopping-cart/' + productId)
            .then((response) => {
              this.items = this.items.filter((item) => item.product_id !== productId)
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
          return
        }
        this.updateLocalStorageCartItems()
        resolve(this.items)
      })
    },
    updateLocalStorageCartItems() {
      // if user not logged in, save again to local storage, otherwise delete from localstorage
      const user = useUserStore()
      if (!user.isLoggedIn) {
        localStorage.setItem('cart_items', JSON.stringify(this.items))
      } else {
        localStorage.removeItem('cart_items')
      }
    },
  },
})

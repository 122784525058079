<script setup lang="ts">
  import { nextTick, ref, watch } from 'vue'
  import { useRoute } from 'vue-router'

  const route = useRoute()

  const emit = defineEmits(['opened', 'closed'])

  defineProps({
    title: {
      type: String,
      default: '',
    },
  })

  const display = ref(false)
  const dim = ref(false)

  watch(
    () => route,
    () => {
      close()
    },
  )

  const open = async () => {
    dim.value = true
    await nextTick()
    display.value = true
    document.addEventListener('keydown', onEscapeListener)
    document.addEventListener('backbutton', onEscapeListener)
  }
  const close = () => {
    display.value = false
    document.removeEventListener('keydown', onEscapeListener)
    document.removeEventListener('backbutton', onEscapeListener)
  }

  const afterLeave = () => {
    dim.value = false
    emit('closed')
  }

  const onEscapeListener = (event: Event) => {
    if (event instanceof KeyboardEvent && event.key === 'Escape') {
      close()
    }
  }

  defineExpose({
    open,
    close,
    afterLeave,
  })
</script>

<template>
  <teleport to="body">
    <div v-if="dim" class="fixed inset-0 z-40 flex items-center justify-center">
      <div class="fixed inset-0 bg-black bg-opacity-40" @click="close"></div>

      <transition name="modal" @after-leave="afterLeave" @after-enter="emit('opened')">
        <div v-if="display" class="container fixed px-4">
          <div class="rounded-2xl bg-white p-4 pr-2">
            <slot name="header">
              <h2 class="pr-2 text-center text-lg font-semibold">
                {{ title }}
              </h2>
            </slot>

            <button class="absolute right-6 top-0 text-3xl text-gray-400" @click="close">⨯</button>

            <div class="my-4 max-h-[80vh] overflow-y-auto pr-2">
              <slot></slot>
            </div>

            <slot name="footer"></slot>
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>

<style lang="postcss" scoped>
  .modal-enter-active,
  .modal-leave-active {
    transition:
      opacity 0.3s ease,
      margin-top 0.3s ease;
  }
  .modal-enter-from,
  .modal-leave-to {
    margin-top: -50px;
    opacity: 0;
  }
</style>

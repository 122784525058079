const telkomsel = ['0811', '0812', '0813', '0821', '0822', '0852', '0853', '0823', '0851']

const indosat = ['0814', '0815', '0816', '0855', '0856', '0857', '0858']

const xl = ['0817', '0818', '0819', '0859', '0877', '0878']

const axis = ['0838', '0831', '0832', '0833']

const three = ['0895', '0896', '0897', '0898', '0899']

const smartfren = ['0881', '0882', '0883', '0884', '0885', '0886', '0887', '0888', '0889']

export function getOperatorFromNumber(phoneNumber: string) {
  const startsWith = phoneNumber.slice(0, 4)

  if (telkomsel.includes(startsWith)) {
    return 'Telkomsel'
  }
  if (indosat.includes(startsWith)) {
    return 'Indosat'
  }
  if (xl.includes(startsWith)) {
    return 'XL'
  }
  if (axis.includes(startsWith)) {
    return 'Axis'
  }
  if (three.includes(startsWith)) {
    return 'Three'
  }
  if (smartfren.includes(startsWith)) {
    return 'Smartfren'
  }

  return ''
}

export function getOperatorLogo(operatorName: string) {
  if (operatorName === '') {
    return ''
  }

  const filename = operatorName.toLowerCase() + '.png'
  return '/img/produk-digital/mobile-operator/' + filename
}

export function getPulsaCategoryId(operatorName: string) {
  switch (operatorName) {
    case 'Axis':
      return 2
    case 'Indosat':
      return 3
    case 'Smartfren':
      return 4
    case 'Telkomsel':
      return 5
    case 'Three':
      return 6
    case 'XL':
      return 7
  }
}

// export function getInternetCategoryId (operatorName: string) {
//   switch (operatorName) {
//   case 'Axis': return 2
//   case 'Indosat': return 3
//   case 'Smartfren': return 4
//   case 'Telkomsel': return 5
//   case 'Three': return 6
//   case 'XL': return 7
//   }
// }

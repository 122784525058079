<script setup lang="ts">
  import { useBack } from '@/functions/composables/back'
  import { getImage } from '@/functions/composables/errorpage'

  const { back } = useBack()
</script>

<template>
  <div class="px-16 pb-16 pt-32">
    <img :src="getImage('404.png')" alt="404" class="mx-auto w-full max-w-sm" />
  </div>
  <div class="px-4 text-center">
    <div class="text-5xl font-bold tracking-wider text-primary-500">OOPS !</div>
    <h1 class="mb-8 text-lg text-gray-500">Halaman tidak dapat ditemukan</h1>
    <div class="text-center">
      <button class="btn btn-primary" @click="back()">Kembali</button>
    </div>
  </div>
</template>

export const WAITING_PAYMENT = 'waiting_payment'
export const WAITING_PAYMENT2 = 'waiting payment'
export const NEW = 'new'
export const PROCESSING = 'processing'
export const DELIVERING = 'delivering'
export const RECEIVED = 'received'
export const CANCEL_REQUESTED = 'cancel_requested'
export const CANCELED = 'canceled'
export const COMPLAINED = 'complained'
export const COMPLAINT_RESPONDED = 'complaint_responded'
export const COMPLETED = 'completed'
export const ONGOING = 'ongoing'
export const FINISHED = 'finished'
export const PENDING = 'pending'
export const SUCCESS = 'success'
export const FAILED = 'failed'
export const UNPROCESSED = 'unprocessed'

export const BERLANGSUNG = 'berlangsung'
export const MENUNGGU_PEMBAYARAN = 'menunggu-pembayaran'
export const MENUNGGU = 'menunggu'
export const DIPROSES = 'diproses'
export const DIKIRIM = 'dikirim'
export const DITERIMA = 'diterima'
export const MINTA_BATAL = 'minta-batal'
export const DIKOMPLAIN = 'dikomplain'
export const KOMPLAIN_DIJAWAB = 'komplain-dijawab'
export const DIBATALKAN = 'dibatalkan'
export const SELESAI = 'selesai'
export const BERHASIL = 'berhasil'
export const GAGAL = 'gagal'

export const getLabel = (str: string) => {
  switch (str) {
    case WAITING_PAYMENT:
      return 'Menunggu Pembayaran'
    case WAITING_PAYMENT2:
      return 'Menunggu Pembayaran'
    case NEW:
      return 'Menunggu Diproses'
    case PROCESSING:
      return 'Pesanan Diproses'
    case DELIVERING:
      return 'Pesanan Dikirim'
    case RECEIVED:
      return 'Pesanan Diterima'
    case CANCEL_REQUESTED:
      return 'Minta Dibatalkan'
    case CANCELED:
      return 'Pesanan Dibatalkan'
    case COMPLAINED:
      return 'Pesanan Dikomplain'
    case COMPLAINT_RESPONDED:
      return 'Komplain Direspon'
    case COMPLETED:
      return 'Pesanan Selesai'
    case PENDING:
      return 'Transaksi Diproses'
    case SUCCESS:
      return 'Transaksi Berhasil'
    case FAILED:
      return 'Transaksi Gagal'
    case UNPROCESSED:
      return 'Transaksi Belum Diproses'
    default:
      return ''
  }
}

import NavbarNoBack from '@/views-partner/components/app-navbar-no-back.vue'
import NavbarWithBack from '@/views-partner/components/app-navbar-with-back.vue'
import NavbarTopHome from '@/views-partner/home/navbar-top.vue'
import NavbarTopSearch from '@/views-partner/search/navbar-top.vue'
import LayoutApp from '@/views/LayoutApp.vue'
import LayoutAuth from '@/views/LayoutAuth.vue'

export default [
  {
    path: '/',
    component: LayoutApp,
    children: [
      {
        path: '/mitra',
        component: () => import('@/views-partner/layout-partner.vue'),
        children: [
          {
            path: '',
            name: 'partner home',
            meta: { title: 'MS Shop' },
            components: {
              default: () => import('@/views-partner/home/page-home.vue'),
              navbarTop: NavbarTopHome,
            },
          },
          {
            path: 'cari-produk',
            name: 'partner search',
            meta: { title: 'Cari Produk' },
            components: {
              default: () => import('@/views-partner/search/page-search.vue'),
              navbarTop: NavbarTopSearch,
            },
          },
          {
            path: 'keranjang',
            name: 'partner cart',
            meta: { title: 'Keranjang' },
            components: {
              default: () => import('@/views-partner/cart/page-cart.vue'),
              navbarTop: NavbarNoBack,
            },
          },
          {
            path: 'produk/digital/pembayaran',
            name: 'partner product digital payment',
            meta: { title: 'Pembayaran' },
            components: {
              default: () => import('@/views-partner/product/digital/page-payment.vue'),
              navbarTop: NavbarWithBack,
            },
          },
          {
            path: 'produk/:id',
            name: 'partner product',
            meta: { title: 'Produk' },
            components: {
              default: () => import('@/views-partner/product/page-product-detail.vue'),
              navbarTop: NavbarTopSearch,
            },
            props: {
              default: true,
            },
          },
          {
            path: 'kategori',
            name: 'partner category',
            meta: { title: 'Kategori Produk' },
            components: {
              default: () => import('@/views-partner/product/category/page-list.vue'),
              navbarTop: NavbarTopSearch,
            },
          },
          {
            path: 'kategori/:id',
            name: 'partner category detail',
            meta: { title: 'Kategori Produk' },
            components: {
              default: () => import('@/views-partner/product/category/page-detail.vue'),
              navbarTop: NavbarTopSearch,
            },
          },
          {
            path: 'pesanan',
            name: 'partner order list',
            meta: { title: 'Pesanan' },
            components: {
              default: () => import('@/views-partner/order/list/page-order-list.vue'),
              navbarTop: NavbarNoBack,
            },
          },
          {
            path: 'pesanan/:id',
            name: 'partner order detail',
            meta: { title: 'Pesanan' },
            components: {
              default: () => import('@/views-partner/order/detail/page-order-detail.vue'),
              navbarTop: NavbarWithBack,
            },
            props: {
              default: true,
            },
          },
          {
            path: 'checkout',
            name: 'partner checkout',
            meta: { title: 'Checkout' },
            components: {
              default: () => import('@/views-partner/order/checkout/page-checkout.vue'),
              navbarTop: NavbarWithBack,
            },
          },
          {
            path: 'akun',
            name: 'partner account',
            meta: { title: 'Akun Saya' },
            components: {
              default: () => import('@/views-partner/account/page-account.vue'),
              navbarTop: NavbarNoBack,
            },
          },
          {
            path: 'akun/ganti-profil',
            name: 'partner account change profile',
            meta: { title: 'Ganti Profil' },
            components: {
              default: () => import('@/views-partner/account/page-change-profile.vue'),
              navbarTop: NavbarWithBack,
            },
          },
          {
            path: 'akun/ganti-password',
            name: 'partner account change password',
            meta: { title: 'Ganti Password' },
            components: {
              default: () => import('@/views/User/ChangePassword.vue'),
              navbarTop: NavbarWithBack,
            },
          },
          {
            path: 'akun/alamat',
            name: 'partner account address',
            meta: { title: 'Alamat' },
            components: {
              default: () => import('@/views-partner/account/page-address.vue'),
              navbarTop: NavbarWithBack,
            },
          },
          {
            path: 'notifikasi',
            name: 'partner notification',
            meta: { title: 'Notifikasi' },
            components: {
              default: () => import('@/views/Notification/PageList.vue'),
              navbarTop: NavbarNoBack,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/r/:referralCode',
    component: LayoutAuth,
    children: [
      {
        path: '',
        component: () => import('@/views-partner/registration/page-register.vue'),
        name: 'partner registration',
        props: true,
        meta: { title: 'Registrasi Akun' },
      },
    ],
  },
]

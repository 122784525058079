import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging'
import type { MessagePayload, Messaging } from 'firebase/messaging'
import { useApiStore } from '@/stores/api'
import { useUserStore } from './stores/user'

const firebaseConfig = {
  apiKey: 'AIzaSyA7ch9ZYSmr_SrTMYJEGfmKknKwTnHUncA',
  authDomain: 'sejati-ms-shop.firebaseapp.com',
  projectId: 'sejati-ms-shop',
  storageBucket: 'sejati-ms-shop.appspot.com',
  messagingSenderId: '145802809007',
  appId: '1:145802809007:web:e440cd71a93d3aa385ce5a',
  measurementId: 'G-0GBVR124J9',
}
const vapidKey =
  'BC6g3isQqpUQuRmRJPJM6frzj76ceyoNuO_hbuHgMyEiry5ze5uUmHnM_ConzEfDNba1qGUhAxVjELiYmIurVVc'

export const startFCM = async () => {
  try {
    const supported = await isSupported()
    if (!supported) {
      const api = useApiStore()
      const token = localStorage.getItem('android_fcm_token')
      if (token) {
        // handle token from android native
        await api.POST('notification/firebase', { device_key: token })
        return
      }

      console.log('[Firebase] Firebase is not supported in this device.')
      return
    }

    const app = initializeApp(firebaseConfig)
    const messaging = getMessaging(app)
    await getAndStoreToken(messaging)
    onMessage(messaging, notificationListener)
  } catch (error) {
    console.log('[Firebase] Error when setting up.')
    console.log('[Firebase]', error)
  }
}

const getAndStoreToken = async (messaging: Messaging) => {
  // ask permission and store firebase token to database
  try {
    const device_key = await getToken(messaging, { vapidKey })
    if (device_key) {
      // Send the token to your server and update the UI if necessary
      const api = useApiStore()
      const user = useUserStore()
      user.setDeviceKey(device_key)
      await api.POST('notification/firebase', { device_key })
    } else {
      // Show permission request UI
      console.log('[Firebase] No registration token available. Request permission to generate one.')
    }
  } catch (err) {
    console.log('[Firebase] An error occurred while retrieving token. ', err)
  }
}

const notificationListener = (payload: MessagePayload) => {
  const title = payload.data?.title ?? ''
  const options = {
    body: payload.data?.body,
    icon: payload.data?.icon,
    data: {
      url: payload.data?.url ?? '',
    },
  }

  // show notification
  const notification = new Notification(title, options)
  notification.onclick = function (event: Event) {
    event.preventDefault()
    window.open((event.currentTarget as Notification).data.url, '_blank')
  }
}

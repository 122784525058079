export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Auth/PageLogin.vue'),
  },
  {
    path: '/password/request',
    name: 'request password',
    component: () => import('../views/Auth/RequestPassword.vue'),
  },
  {
    path: '/password/reset/:token',
    name: 'set password',
    props: true,
    component: () => import('../views/Auth/SetPassword.vue'),
  },
]

import { useRouter } from 'vue-router'

export function useBack() {
  const router = useRouter()

  const back = (defaultRouteName: string = 'home') => {
    if (window.history.state.position > 1) {
      router.go(-1)
    } else {
      router.push({ name: defaultRouteName })
    }
  }

  return {
    back,
  }
}

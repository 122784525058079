import type { RouteRecordRaw } from 'vue-router'

const Navbar = () => import('@/components/TheNavbarTop.vue')

const routes: RouteRecordRaw[] = [
  {
    path: '',
    name: 'mscash logs',
    components: {
      default: () => import('../views/Mscash/PageIndex.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'MS Cash',
      },
    },
  },
  {
    path: 'topup',
    name: 'topup',
    components: {
      default: () => import('../views/Mscash/Topup/Create/PageIndex.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'Topup',
      },
    },
  },
  {
    path: 'topup/konfirmasi',
    name: 'topup confirmation',
    components: {
      default: () => import('../views/Mscash/Topup/Create/TopupConfirmation.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'Topup',
      },
    },
  },
  {
    path: 'topup/riwayat',
    name: 'topup history',
    components: {
      default: () => import('../views/Mscash/Topup/History/PageIndex.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'Riwayat Topup',
      },
    },
  },
  {
    path: 'topup/:id',
    name: 'topup detail',
    components: {
      default: () => import('../views/Mscash/Topup/Detail/PageIndex.vue'),
      navbarTop: Navbar,
    },
    props: {
      navbarTop: {
        title: 'Detail Topup',
      },
      default: true,
    },
  },
]

export default routes

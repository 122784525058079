<script setup lang="ts">
  import { useRoute } from 'vue-router'
  import IconNotification from '@/components/AppNavbarIconNotification.vue'
  import { useBack } from '@/functions/composables/back'
  import IconChat from './app-navbar-icon-chat.vue'

  const route = useRoute()

  const { back } = useBack()
</script>

<template>
  <div id="navbar" class="navbar-top bg-white">
    <div class="container flex h-16 items-center px-4 py-2">
      <div class="-ml-2">
        <button
          aria-label="Back"
          class="pointer-events-auto relative mr-2 flex items-center justify-center p-4 text-gray-500"
          @click="back('partner home')"
        >
          <fa-icon icon="i-fas-chevron-left" class="absolute"></fa-icon>
        </button>
      </div>
      <slot>
        <h1 class="font-semibold">
          {{ route.meta.title }}
        </h1>
      </slot>
      <div class="-mr-1 ml-auto flex space-x-1 text-gray-500">
        <icon-chat></icon-chat>
        <icon-notification></icon-notification>
      </div>
    </div>
  </div>
</template>

import axios from 'axios'
import type { AxiosRequestConfig } from 'axios'
import { defineStore } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import { usePageStore } from './page'
import { useToastStore } from './toast'
import { useUserStore } from './user'
import { useUserPreferencesStore } from './userPreferences'

const config = () => {
  const userPreferences = useUserPreferencesStore()
  return {
    baseURL: import.meta.env.VITE_BASE_API,
    timeout: 30000, // 30s
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'User-Address-Id': userPreferences.default_address ?? undefined,
    },
    withCredentials: true,
    withXSRFToken: true,
  }
}

export const useApiStore = defineStore('Api', () => {
  const page = usePageStore()
  const toast = useToastStore()
  const user = useUserStore()
  const route = useRoute()
  const router = useRouter()

  const REQUEST = async <T>(conf: AxiosRequestConfig, retryIfCsrfError = true): Promise<T> => {
    try {
      const response = await axios.request({ ...config(), ...conf })
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 419 && retryIfCsrfError) {
        await GET('/sanctum/csrf-cookie')
        return await REQUEST<T>(conf, false)
      } else {
        handleErrors(error)
        return Promise.reject(error)
      }
    }
  }
  const GET = <T>(url: string, params = {}) => {
    return REQUEST<T>({ method: 'get', url, params })
  }
  const POST = <T>(url: string, data: object = {}) => {
    return REQUEST<T>({ method: 'post', url, data })
  }
  const PUT = <T>(url: string, data: object) => {
    return REQUEST<T>({ method: 'put', url, data })
  }
  const DELETE = <T>(url: string, data = {}) => {
    return REQUEST<T>({ method: 'delete', url, data })
  }

  const onError = {
    unauthorized: () => {
      if (route.name === 'login') {
        return
      }
      user.$reset()
      const path = route.fullPath.substring(1)
      const query = path !== '' ? { r: path } : undefined
      router.replace({ name: 'login', query })
    },
    maintenance: () => {
      router.replace({ name: 'maintenance' })
    },
    forbidden: () => {
      page.showNotFoundError()
    },
    notFound: () => {
      page.showNotFoundError()
    },
    csrfTokenMismatch: async <T>(
      resolve: (value: T) => void,
      reject: (reason: unknown) => void,
      conf: AxiosRequestConfig,
    ) => {
      try {
        await GET('/sanctum/csrf-cookie')
        resolve(await REQUEST<T>(conf))
      } catch (error) {
        reject(error)
      }
    },
    validationFailed: () => {
      toast.add('Terdapat kesalahan pada data yang dikirim')
      setTimeout(() => {
        const errorMessage = document.querySelector('.error-message')
        errorMessage?.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    },
    tooManyRequest: () => {
      toast.add('Silakan tunggu beberapa menit sebelum mencoba kembali')
    },
    networkError: () => {
      toast.add('Tidak terhubung dengan internet')
    },
  }

  /* https://github.com/axios/axios#handling-errors */
  const handleErrors = (error: unknown) => {
    if (!axios.isAxiosError(error)) {
      /**
       * Not axios error
       * Something happened in setting up the request that triggered an Error
       */
      console.error('[NotAxiosError]', error)
      return
    }

    if (error.response) {
      /**
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      switch (error.response.status) {
        case 401:
          onError.unauthorized()
          break
        case 403:
          onError.forbidden()
          break
        case 404:
          onError.notFound()
          break
        // case 419:
        //   onError.csrfTokenMismatch(resolve, reject, conf)
        //   return
        case 422:
          onError.validationFailed()
          break
        case 429:
          onError.tooManyRequest()
          break
        case 503:
          onError.maintenance()
          break
        default:
          break
      }
      if (error.message === 'Network Error') {
        onError.networkError()
      }
      console.error('[Axios error]', error)
      return
    }

    /**
     * The request was made but no response was received.
     * `error.request` is an instance of XMLHttpRequest
     * in the browser and an instance of
     * http.ClientRequest in node.js
     */
    // if (error.request) {
    // }
    console.error('[API] Axios error but no response', error)
  }

  const formErrors = (error: unknown): FormError => {
    if (axios.isAxiosError(error) && error.response?.data.errors) {
      return error.response.data.errors
    }

    return {}
  }

  return {
    GET,
    POST,
    DELETE,
    PUT,
    config,
    formErrors,
  }
})

import { createPinia } from 'pinia'
import 'virtual:uno.css'
import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import GlobalComponents from './components'
import GlobalDirectives from './directives'
import Router from './router'
import Sentry from './services/Sentry'

const app = createApp(App)

// app.config.performance = true

app
  .use(createPinia())
  .use(Router)
  .use(Sentry, Router)
  .use(GlobalComponents)
  .use(GlobalDirectives)
  .mount('#app')

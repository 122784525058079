import { defineStore } from 'pinia'
import { useUserPreferencesStore } from '@/stores/userPreferences'
import { usePartnerStore } from './partner'

interface CompanyAddress {
  id: number
  label: string
  address: string
  region_id: number
  region: ProductRegion
  additional_fee_value: number | null
  additional_fee_is_percent: number | null
  additional_fee_label: string | null
}
interface Company {
  id: number
  name: string
  prefix: string | null
  full_name: string
  addresses: CompanyAddress[]
}
interface Employee {
  id: number
  user_id: number
  company_id: number
  company: Company
  points: number
  mscash: number
  current_credits: number
  credits_reset_at: string
  points_expired_at: string
}
interface Product {
  regions: ProductRegion[]
}

interface State {
  id: number
  name: string
  email: string
  employee?: Employee
  count_notifications: number
  device_key?: string
}

export const useUserStore = defineStore('User', {
  state: (): State => ({
    id: 0,
    name: '',
    email: '',
    employee: undefined,
    count_notifications: 0,
    device_key: undefined,
  }),
  getters: {
    isLoggedIn(state) {
      const partner = usePartnerStore()
      return !!state.id || !!partner.user
    },
    addresses(state): CompanyAddress[] {
      return state.employee?.company?.addresses ?? []
    },
    selectedAddress(): CompanyAddress | null {
      if (this.addresses.length === 0) {
        return null
      }

      const userPreferences = useUserPreferencesStore()
      const findAddress = this.addresses.find((address) => {
        return address.id === userPreferences.default_address
      })

      return findAddress ?? this.addresses[0]
    },
    selectedRegionId(): number | null {
      if (!this.isLoggedIn || this.addresses.length === 0) {
        return null
      }

      return this.selectedAddress?.region_id ?? null
    },
    canPurchase(state) {
      return (data: Product | ProductBundle): boolean => {
        // admins
        if (state.employee === null) {
          return true
        }

        if (data.regions.length === 0) {
          return true
        }

        // user with region can only see product with same region
        return data.regions.some((region) => region.id === this.selectedRegionId)
      }
    },
    points: (state) => state.employee?.points ?? 0,
    credits: (state) => state.employee?.current_credits ?? 0,
    mscash: (state) => state.employee?.mscash ?? 0,
  },
  actions: {
    setShopInitResponse(user: State) {
      this.id = user.id
      this.name = user.name
      this.email = user.email
      this.count_notifications = user.count_notifications

      if (user.employee) {
        this.employee = {
          id: user.employee.id,
          user_id: user.employee.user_id,
          company_id: user.employee.company_id,
          company: {
            id: user.employee.company.id,
            name: user.employee.company.name,
            prefix: user.employee.company.prefix,
            full_name: user.employee.company.full_name,
            addresses: user.employee.company.addresses,
          },
          points: user.employee.points,
          mscash: user.employee.mscash,
          current_credits: user.employee.current_credits,
          points_expired_at: user.employee.points_expired_at,
          credits_reset_at: user.employee.credits_reset_at,
        }
      }
    },
    setDeviceKey(key: string) {
      this.device_key = key
    },
  },
})

<script setup lang="ts">
  import { addHours } from 'date-fns'
  import { computed, nextTick, ref, watch } from 'vue'
  import { useApiStore } from '@/stores/api'

  interface HomePopup {
    id: number
    title: string
    link: string | null
    order: number
    image_url: string | null
    seen?: boolean
    clicked?: boolean
  }

  const api = useApiStore()

  const dim = ref(false)
  const popups = ref<HomePopup[]>([])

  const shouldShowPopup = () => {
    // get the next time to show popup
    const showPopupAfterHour = 24
    let nextViewPopup = Number(localStorage.getItem('home_popup_next_view'))
    if (Date.now() < nextViewPopup) {
      // if stored next time is set and greater than now, don't do anything
      return false
    }
    // stored when the next time popup should be shown
    nextViewPopup = addHours(new Date(), showPopupAfterHour).getTime()
    localStorage.setItem('home_popup_next_view', String(nextViewPopup))
    return true
  }
  const loadData = async () => {
    const response = await api.GET<HomePopup[]>('home-popup/app')
    if (response.length) {
      dim.value = true
      await nextTick() // needed for transition
      popups.value = response.map((popup) => ({
        ...popup,
        seen: false,
        clicked: false,
      }))
      submitSee(popups.value[0])
    }
  }
  const init = () => {
    if (shouldShowPopup()) {
      // check if popups should be shown
      loadData()
    }
  }
  const close = () => {
    popups.value = []
  }
  const afterLeave = () => {
    dim.value = false
  }

  const currentIndex = ref(0)
  const currentPopup = computed(() => popups.value[currentIndex.value] ?? null)
  const nextImage = () => {
    if (currentIndex.value < popups.value.length - 1) {
      currentIndex.value++
    }
  }
  const prevImage = () => {
    if (currentIndex.value > 0) {
      currentIndex.value--
    }
  }
  watch(currentPopup, (currImage) => {
    submitSee(currImage)
  })

  const submitSee = async (popup: HomePopup) => {
    if (popup?.seen === false) {
      try {
        await api.GET('home-popup/view/' + popup.id)
      } finally {
        popup.seen = true
      }
    }
  }
  const submitClick = async (popup: HomePopup) => {
    if (popup?.clicked === false) {
      try {
        await api.GET('home-popup/click/' + popup.id)
      } finally {
        popup.clicked = true
      }
    }
  }

  init()
</script>

<template>
  <teleport to="body">
    <div v-if="dim" class="fixed inset-0 z-30 flex items-center justify-center">
      <div class="fixed inset-0 bg-black bg-opacity-40"></div>

      <transition name="modal" @after-leave="afterLeave">
        <div v-if="popups.length" class="fixed w-full px-2">
          <div class="relative mx-auto px-10 py-8" style="max-width: 400px">
            <div class="absolute inset-0 flex items-center justify-between">
              <div>
                <button v-if="currentIndex > 0" class="btn-popup btn-popup-nav" @click="prevImage">
                  &lsaquo;
                </button>
              </div>
              <div>
                <button
                  v-if="currentIndex < popups.length - 1"
                  class="btn-popup btn-popup-nav"
                  @click="nextImage"
                >
                  &rsaquo;
                </button>
              </div>
            </div>
            <div class="relative overflow-hidden">
              <a
                :href="currentPopup.link ?? undefined"
                class="block aspect-popup-home"
                @click="submitClick(currentPopup)"
              >
                <g-img
                  class="aspect-popup-home w-full"
                  :src="currentPopup.image_url"
                  :alt="currentPopup.title"
                ></g-img>
              </a>
            </div>
            <div class="absolute right-0 top-0">
              <button class="btn-popup" @click="close">✖</button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </teleport>
</template>

<style lang="postcss" scoped>
  .btn-popup {
    @apply flex h-8 w-8 items-center justify-center rounded-full bg-black bg-opacity-25 text-white;
  }
  .btn-popup-nav {
    @apply text-2xl;
  }

  .modal-enter-active,
  .modal-leave-active {
    transition:
      opacity 0.3s ease,
      transform 0.3s ease;
  }
  .modal-enter-from,
  .modal-leave-to {
    /* margin-top: -50px; */
    transform: scale(50%);
    opacity: 0;
  }
</style>
